import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Parser from "html-react-parser";
import {
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Container,
} from "reactstrap";
import { object } from "prop-types";
import debounce from "lodash.debounce";
import NotificationAlert from "react-notification-alert";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;
const VASHISHT_API_URL = process.env.REACT_APP_VASHISHT_API;

const Matchmaking = () => {
  const history = useHistory();
  const plan_status = useSelector((e) => e.plan);
  const splans = plan_status.subs_plan;
  const planStatus = plan_status.plan_status;
  const userId = JSON.parse(localStorage.getItem("user")).id;
  const userName = JSON.parse(localStorage.getItem("user")).name;
  const [language, setLanguage] = useState("");
  const [languageList, setLanguageList] = useState([]);
  // boy
  const [inputValuesBoy, setInputValueBoy] = useState({
    place: "",
    dst: "",
    latitude: "",
    longitude: "",
  });
  // girl
  const [inputValuesGirl, setInputValueGirl] = useState({
    place: "",
    dst: "",
    latitude: "",
    longitude: "",
  });

  const [data, setData] = useState({});
  const [inputValues, setInputValue] = useState({
    language: "1",
  });

  const [formData, setFormData] = useState({
    boy: {
      name: "",
      placeOfBirth: "",
      dob: { day: "", month: "", year: "" },
      tob: { hours: "", minutes: "", period: "AM" },
      latitude: "",
      longitude: "",
      timezone: "",
    },
    girl: {
      name: "",
      placeOfBirth: "",
      dob: { day: "", month: "", year: "" },
      tob: { hours: "", minutes: "", period: "AM" },
      latitude: "",
      longitude: "",
      timezone: "",
    },
  });

  const [errors, setErrors] = useState({});
  const [tabDisabled, setTabDisabled] = useState(false);
  const usertoken = localStorage.getItem("vjtoken");
  const token = localStorage.getItem("token");
  const notificationAlertRef = React.useRef(null);

  //   console.log(formData, "formData");

  const validateForm = () => {
    let formErrors = {};
    const regex = /^[A-Za-z\s]+$/;

    // Name Validation
    if (!formData.boy.name.match(regex)) {
      formErrors.boyName = "Please enter valid name for the boy.";
    }
    if (!formData.girl.name.match(regex)) {
      formErrors.girlName = "Please enter valid name for the girl.";
    }

    // Place of Birth Validation
    if (!inputValuesBoy.place.trim()) {
      formErrors.boyPlaceOfBirth = "Place of birth for the boy is required.";
    }
    if (!inputValuesGirl.place.trim()) {
      formErrors.girlPlaceOfBirth = "Place of birth for the girl is required.";
    }

    // DOB Validation
    const validateDOB = (dob) => {
      const day = dob.day;
      const month = dob.month;
      const year = dob.year;
      if (
        !day.match(/^(0[1-9]|[12][0-9]|3[01])$/) ||
        !month.match(/^(0[1-9]|1[012])$/) ||
        !year.match(/^(19[3-9]\d|2[01]\d{2}|22[0-2][0-4])$/)
      ) {
        return "Please enter a valid date (DD-MM-YYYY).";
      }
      return null;
    };

    const boyDOBError = validateDOB(formData.boy.dob);
    if (boyDOBError) formErrors.boyDOB = boyDOBError;

    const girlDOBError = validateDOB(formData.girl.dob);
    if (girlDOBError) formErrors.girlDOB = girlDOBError;

    // TOB Validation
    const validateTOB = (tob) => {
      const hours = tob.hours;
      const minutes = tob.minutes;
      if (
        !hours.match(/^(0[0-9]|1[0-9]|2[0-3])$/) ||
        !minutes.match(/^[0-5][0-9]$/)
      ) {
        return "Please enter a valid time (HH:MM).";
      }
      return null;
    };

    const boyTOBError = validateTOB(formData.boy.tob);
    if (boyTOBError) formErrors.boyTOB = boyTOBError;

    const girlTOBError = validateTOB(formData.girl.tob);
    if (girlTOBError) formErrors.girlTOB = girlTOBError;

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  /// handle on change
  const handleInputChange = (e, person, field, subfield) => {
    const { name, value } = e.target;

    setFormData((prevState) => {
      if (subfield) {
        // Handle nested fields like dob and tob
        return {
          ...prevState,
          [person]: {
            ...prevState[person],
            [field]: {
              ...prevState[person][field],
              [subfield]: value,
            },
          },
        };
      } else {
        // Handle top-level fields like name and placeOfBirth
        return {
          ...prevState,
          [person]: {
            ...prevState[person],
            [field]: value,
          },
        };
      }
    });
  };

  const addZeros = (e, person, field, subfield) => {
    const { value } = e.target;
    setFormData((prevState) => {
      let updatedValue = value;

      // Check if zero-padding is needed
      if (field === "dob" || field === "tob") {
        // Adjust field names as necessary
        const numericValue = Number(value);
        if (numericValue >= 1 && numericValue <= 9) {
          updatedValue = String(numericValue).padStart(2, "0"); // Convert to string and pad
        }
      }

      if (subfield) {
        // Handle nested fields
        return {
          ...prevState,
          [person]: {
            ...prevState[person],
            [field]: {
              ...prevState[person][field],
              [subfield]: updatedValue,
            },
          },
        };
      } else {
        // Handle top-level fields
        return {
          ...prevState,
          [person]: {
            ...prevState[person],
            [field]: updatedValue,
          },
        };
      }
    });
  };

  // console.log(inputValuesGirl.placeOfBirth, "payloaff");

  const fetchMatchmakingData = async (e) => {
    if (planStatus === 0) {
      history.push("/acharya/dashboard");
    } else if (planStatus === 1) {
    } else {
      history.push("/acharya/dashboard");
    }
    e.preventDefault();
    let data = {
      acharyaid: userId,
      payload: {
        boydetails: {
          name: formData.boy.name,
          birthDate: {
            day: formData.boy.dob.day,
            month: formData.boy.dob.month,
            year: formData.boy.dob.year,
          },
          birthTime: {
            hour: formData.boy.tob.hours,
            minute: formData.boy.tob.minutes,
            second: 0,
          },
          timeZone: inputValuesBoy.dst,
          gender: "male",
          product: "",
          kundaliType: "kp",
          rotateKundali: "",
          placeOfBirth: inputValuesBoy.place,
          longitude: inputValuesBoy.longitude,
          latitude: inputValuesBoy.latitude,
          lagan: "500",
        },
        girldetails: {
          name: formData.girl.name,
          birthDate: {
            day: formData.girl.dob.day,
            month: formData.girl.dob.month,
            year: formData.girl.dob.year,
          },
          birthTime: {
            hour: formData.girl.tob.hours,
            minute: formData.girl.tob.minutes,
            second: 0,
          },
          timeZone: inputValuesGirl.dst,
          gender: "female",
          product: "",
          kundaliType: "kp",
          rotateKundali: "",
          placeOfBirth: inputValuesGirl.place,
          longitude: inputValuesGirl.longitude,
          latitude: inputValuesGirl.latitude,
          lagan: "500",
        },
        userName: userName,
        language: parseInt(inputValues.language),
        showRef: false,
        paid: true,
      },
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${BAS_URL_APPAPI}acharya/matchmaking`,
      headers: {
        "Auth-token": token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    if (validateForm()) {
      setTabDisabled(true);
      try {
        const response = await axios.request(config);
        if (response.data.success === 1) {
          setData(response.data.data && response.data.data);
        }
        setTabDisabled(false);
        //   setResponseData(response.data && response.data); // Save the response data
      } catch (error) {
        setTabDisabled(false);
        //   setError(error.message && error.message  || "An error occurred"); // Save the error message
      } finally {
        setTabDisabled(false);
        //   setLoading(false); // Set loading to false when the API call completes
      }
    }
  };

  // boy
  const [dropDownBoy, setShowDropdownBoy] = useState(false);
  const [placeListBoy, setPlaceListBoy] = useState([]);

  const [placeBoy, setPlaceBoy] = useState("");

  function handleChangeBoy(event) {
    const { name, value } = event.target;
    setInputValueBoy({ ...inputValuesBoy, [name]: value });
  }

  const selectPlaceBoy = (val) => {
    setInputValueBoy({
      ...inputValuesBoy,
      pob: val,
      place: val.placeName.toString(),
      latitude: val.latitude.toString(),
      longitude: val.longitude.toString(),
    });
    setShowDropdownBoy(false);
  };

  console.log(inputValuesBoy, "inputValuesinputValues");

  const searchPlaceAPIBoy = async (text) => {
    let token = localStorage.getItem("token");
    try {
      // const result = await axios.post(`${BAS_URL_APPAPI}acharya/getcredt`);
      const usertoken = localStorage.getItem("vjtoken");
      if (text.length > 0) {
        const body = { district: text };
        const requestOptions = {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + usertoken,
          },
        };
        let body1 = JSON.stringify(body);
        let res = await axios.post(
          `${VASHISHT_API_URL}place/search-place`,
          body1,
          requestOptions
        );
        setPlaceListBoy(res.data.data);
      } else {
        setPlaceListBoy();
      }
    } catch (err) {}
  };

  const debouncedSearchBoy = useCallback(
    debounce((nextValue) => searchPlaceAPIBoy(nextValue), 500),
    [] // will be created only once initially
  );

  const searchPlaceBoy = (e) => {
    const { value: nextValue } = e.target;
    if (e.target.value === "") {
      setShowDropdownBoy(false);
    } else {
      setShowDropdownBoy(true);
    }
    setPlaceBoy(e.target.value);
    debouncedSearchBoy(nextValue);
  };

  // Girl
  const [dropDownGirl, setShowDropdownGirl] = useState(false);
  const [placeListGirl, setPlaceListGirl] = useState([]);
  const [placeGirl, setPlaceGirl] = useState("");

  function handleChangeGirl(event) {
    const { name, value } = event.target;
    setInputValueGirl({ ...inputValuesGirl, [name]: value });
  }

  const selectPlaceGirl = (val) => {
    setInputValueGirl({
      ...inputValuesGirl,
      pob: val,
      place: val.placeName.toString(),
      latitude: val.latitude.toString(),
      longitude: val.longitude.toString(),
      dst: val.timezone,
    });
    setShowDropdownGirl(false);
  };

  console.log(inputValuesGirl, "inputValues");

  const searchPlaceAPIGirl = async (text) => {
    let token = localStorage.getItem("token");
    try {
      const usertoken = localStorage.getItem("vjtoken");
      if (text.length > 0) {
        const body = { district: text };
        const requestOptions = {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + usertoken,
          },
        };
        let body1 = JSON.stringify(body);
        let res = await axios.post(
          `${VASHISHT_API_URL}place/search-place`,
          body1,
          requestOptions
        );
        setPlaceListGirl(res.data.data);
      } else {
        setPlaceListGirl();
      }
    } catch (err) {}
  };

  const debouncedSearchGirl = useCallback(
    debounce((nextValue) => searchPlaceAPIGirl(nextValue), 500),
    [] // will be created only once initially
  );

  const searchPlaceGirl = (e) => {
    const { value: nextValue } = e.target;
    if (e.target.value === "") {
      setShowDropdownGirl(false);
    } else {
      setShowDropdownGirl(true);
    }
    setPlaceGirl(e.target.value);
    debouncedSearchGirl(nextValue);
  };

  // console.log(planStatus, "planStatus");

  useEffect(() => {
    if (splans != 4) {
      history.push("/acharya/dashboard");
    }
    if (planStatus === 0) {
      history.push("/acharya/dashboard");
    } else if (planStatus === 1) {
    } else {
      history.push("/acharya/dashboard");
    }
  }, []);

  const clear = () => {
    setFormData({
      ...formData,
      boy: {
        name: "",
        placeOfBirth: "",
        dob: { day: "", month: "", year: "" },
        tob: {
          hours: "",
          minutes: "",
          period: "AM",
        },
      },
    });
    setData({});
    setInputValueBoy({
      place: "",
      dst: "",
      latitude: "",
      longitude: "",
    });
    setFormData({
      ...formData,
      girl: {
        name: "",
        placeOfBirth: "",
        dob: { day: "", month: "", year: "" },
        tob: {
          hours: "",
          minutes: "",
          period: "AM",
        },
      },
    });
    setData({});
    setInputValueGirl({
      place: "",
      dst: "",
      latitude: "",
      longitude: "",
    });
  };


  const languageSelect = (e) => {
    // dispatch(selectedlanguage(e.target.value));
    setLanguage(e.target.value);
  };

  // langauage
  const getLanguages = async () => {
    // const result = await axios.post(`${BAS_URL_APPAPI}acharya/getcredt`);
    const usertoken = localStorage.getItem("vjtoken");
    const vuserid = userId;
    const config = {
      headers: {
        authorization: "Bearer " + usertoken,
        "Content-Type": "application/json",
      },
    };
    let res = await axios.get(`${VASHISHT_API_URL}language`, config);
    if (res.data["success"] === 0 && res.data["messsge"] === "Invalid token") {
      // const result = await axios.post(`${BAS_URL_APPAPI}acharya/getcredt`);
      const usertoken = localStorage.getItem("vjtoken");
      const config = {
        headers: {
          authorization: "Bearer " + usertoken,
          "Content-Type": "application/json",
        },
      };
      res = await axios.get(`${VASHISHT_API_URL}language`, config);
    }
    setLanguageList(res.data.data);
  };

 


   //handle submit updates
   function handleChange(event) {
    const { name, value } = event.target;
    // if (value) {
    //   validation[name] = "";
    // }
    setInputValue({ ...inputValues, [name]: value });
  }


  useEffect(() =>{
    getLanguages();
  },[])

  return (
    <>
      <div className="content md:px-2">
        <NotificationAlert ref={notificationAlertRef} />

        <div
          className="cs_perloader"
          style={{ display: tabDisabled ? "" : "none", background: "none" }}
        >
          {/* <span className="cs_perloader_text t-1/2">Loading...</span> */}
        </div>
        <div
          style={{
            paddingLeft: "-15px",
            paddingRight: "-15px",
          }}
        >
          <Container fluid className="px-0">
            <Row className="mb-0 mt-1 px-2" noGutters>
              <Col lg={12} xs={12} md={12}>
                <Card className="mb-3">
                  <CardBody className="mt-0 shadow-sm rounded-sm">
                    <ButtonGroup
                      className="btn-group-toggle d-flex ml-[5px]"
                      data-toggle="buttons"
                      style={{ gap: 3 }}
                    >
                      <Row>
                        <Col md="12">
                          <Link
                            className="btn btn-simple"
                            style={{
                              height: "40px",
                              alignContent: "center",
                              padding: "5px 16px",
                              fontSize: "12px",
                              color: "#263148",
                              background: "transparent",
                              border: "1px solid #212529",
                              borderRadius: "4px",
                            }}
                            tabindex="18"
                            to="/acharya/dashboard"
                          >
                            Horoscope
                          </Link>
                          {/* <Link className="btn" href="#preddiv" style={{padding:"4px 16px",fontSize:"12px",color:"#1d8cf8",borderColor:"#1d8cf8",background:"transparent",border: "1px solid",borderRadius: "4px"}} tabindex="18" to="/acharya/dashboard">Prediction</Link> */}
                          {/* <Link
                            className="btn btn-simple"
                            style={{
                              height: "40px",
                              alignContent: "center",
                              padding: "5px 16px",
                              fontSize: "12px",
                              color: "#263148",
                              background: "transparent",
                              border: "1px solid #212529",
                              borderRadius: "4px",
                            }}
                            tabindex="18"
                            to="/acharya/questions"
                          >
                            Questions
                          </Link> */}
                          {
                            <Link
                              className="btn active"
                              style={{
                                height: "40px",
                                alignContent: "center",
                                padding: "5px 16px",
                                fontSize: "12px",
                                color: "white",
                                backgroundImage:
                                  "linear-gradient(to bottom left, #344675, #263148, #344675)",
                                border: "1px solid #212529",
                                borderRadius: "4px",
                              }}
                              tabindex="18"
                              to="/acharya/match-making"
                            >
                              Match Making
                            </Link>
                          }
                        </Col>
                      </Row>
                    </ButtonGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <Container fluid className="px-0">
            <Row className="mb-0 mt-1 px-2" noGutters>
              <Card className="">
                <div className="container">
                  <div className="row pt-3 px-3 flex justify-end items-center">
                      <div  className="mt-1 pr-2 text-gray-950"><strong>PDF Language </strong></div>
                      <select
                           title="Pdf langauage"
                            bsSize="sm"
                            type="select"
                            className="form-control col-2 py-0 px-3 cursor-pointer"
                            name="language"
                            value={inputValues.language}
                            style={{ marginTop: "6px" }}
                            onChange={(e) => {
                              languageSelect(e);
                              handleChange(e);
                            }}
                            tabIndex="3"
                            required
                            readOnly={data.pdflink}
                            // disabled={alldatatrue}
                          >
                            <option selected >Select Language</option>
                            {languageList.map((val) => (
                              <option
                                value={val.id}
                              >
                                {val.language}
                              </option>
                            ))}
                          </select>
                    </div>

                  <div className="row pt-2">
                    {/* Boy's Details */}
                    <div className="col-lg-6 py-2">
                      <div className="p-4 border rounded-lg border-dark">
                        <div className="flex justify-between">
                          <h4 className="mb-0">Boy's Details</h4>
                          <button
                            type="button"
                            className="btn btn-sm py-2.5 px-3 btn-primary"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                boy: {
                                  name: "",
                                  placeOfBirth: "",
                                  dob: { day: "", month: "", year: "" },
                                  tob: {
                                    hours: "",
                                    minutes: "",
                                    period: "AM",
                                  },
                                },
                              });
                              setData({});
                              setErrors({});
                              setInputValueBoy({
                                place: "",
                                dst: "",
                                latitude: "",
                                longitude: "",
                              });
                            }}
                          >
                            Reset
                          </button>
                        </div>

                        {/* Name */}
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder="Name"
                          tabIndex="1"
                          value={formData.boy.name}
                          readOnly={data.pdflink}
                          onChange={(e) => {
                            handleInputChange(e, "boy", "name");
                            setErrors((prev) => ({ ...prev, boyName: "" }));
                          }}
                        />
                        {errors.boyName && (
                          <div>
                            <small style={{ color: "red" }}>
                              {errors.boyName}
                            </small>
                          </div>
                        )}

                        {/* Place of Birth */}
                        <label>Place of birth</label>
                        <div className="relative">
                          <input
                            type="text"
                            className={`form-control ${
                              dropDownBoy &&
                              "rounded-t-lg rounded-ee-none rounded-es-none border-bottom-0 form-control"
                            }`}
                            name="place"
                            tabIndex="2"
                            placeholder="Place of Birth"
                            value={inputValuesBoy.place}
                            readOnly={data.pdflink}
                            onChange={(e) => {
                              searchPlaceBoy(e);
                              handleChangeBoy(e);
                              setErrors((prev) => ({
                                ...prev,
                                boyPlaceOfBirth: "",
                              }));
                            }}
                            autoComplete="off"
                          />
                          {dropDownBoy && (
                            <div
                              style={{ border: "1px solid #929699" }}
                              className="rounded-b-lg rounded-t-0 border-top-0 absolute bg-white z-10 w-full"
                            >
                              <div className="h-[200px] overflow-scroll mb-0">
                                {placeListBoy &&
                                  placeListBoy.map((val, index) => (
                                    <button
                                      className="list-none hover:bg-gray-300 cursor-pointer btn-block text-start px-2 flex"
                                      key={index}
                                      role="button"
                                      onClick={() => selectPlaceBoy(val)}
                                    >
                                      {val.placeName && (
                                        <div className="placeName">
                                          {val.placeName}
                                        </div>
                                      )}
                                      <div className="placeName">{"-"}</div>
                                      {val.StateName && (
                                        <div className="StateName">
                                          {val.StateName}
                                        </div>
                                      )}
                                      {val.StateName && (
                                        <div className="placeName">{"-"}</div>
                                      )}
                                      <div className="countryName">
                                        {val.countryName}
                                      </div>
                                    </button>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>
                        {errors.boyPlaceOfBirth && (
                          <div className="row">
                            <div className="col-12">
                              <small style={{ color: "red" }}>
                                {errors.boyPlaceOfBirth}
                              </small>
                            </div>
                          </div>
                        )}

                        {/* DOB */}
                        <label>DOB</label>
                        <div className="flex" style={{ gap: 10 }}>
                          <input
                            type="text"
                            className="form-control"
                            name="day"
                            placeholder="Day"
                            tabIndex="3"
                            value={formData.boy.dob.day}
                            readOnly={data.pdflink}
                            onBlur={(e) => {
                              addZeros(e, "boy", "dob", "day");
                            }}
                            onChange={(e) => {
                              handleInputChange(e, "boy", "dob", "day");
                              setErrors((prev) => ({ ...prev, boyDOB: "" }));
                            }}
                          />
                          <input
                            type="text"
                            className="form-control"
                            name="month"
                            placeholder="Month"
                            tabIndex="4"
                            value={formData.boy.dob.month}
                            readOnly={data.pdflink}
                            onBlur={(e) => {
                              addZeros(e, "boy", "dob", "month");
                            }}
                            onChange={(e) => {
                              handleInputChange(e, "boy", "dob", "month");
                              setErrors((prev) => ({ ...prev, boyDOB: "" }));
                            }}
                          />
                          <input
                            type="text"
                            className="form-control"
                            name="year"
                            tabIndex="5"
                            placeholder="Year"
                            value={formData.boy.dob.year}
                            readOnly={data.pdflink}
                            onBlur={(e) => {
                              addZeros(e, "boy", "dob", "year");
                            }}
                            onChange={(e) => {
                              handleInputChange(e, "boy", "dob", "year");
                              setErrors((prev) => ({ ...prev, boyDOB: "" }));
                            }}
                          />
                        </div>
                        {errors.boyDOB && (
                          <div className="row">
                            <div className="col-12">
                              <small style={{ color: "red" }}>
                                {errors.boyDOB}
                              </small>
                            </div>
                          </div>
                        )}

                        {/* TOB */}
                        <label className="mt-1">TOB</label>
                        <div className="flex" style={{ gap: 10 }}>
                          <input
                            type="number"
                            className="form-control"
                            name="hours"
                            tabIndex="6"
                            placeholder="Hours"
                            value={formData.boy.tob.hours}
                            readOnly={data.pdflink}
                            onBlur={(e) => {
                              addZeros(e, "boy", "tob", "hours");
                            }}
                            onChange={(e) => {
                              handleInputChange(e, "boy", "tob", "hours");
                              setErrors((prev) => ({ ...prev, boyTOB: "" }));
                            }}
                          />
                          <input
                            type="number"
                            className="form-control"
                            name="minutes"
                            tabIndex="7"
                            placeholder="Minutes"
                            value={formData.boy.tob.minutes}
                            readOnly={data.pdflink}
                            onBlur={(e) => {
                              addZeros(e, "boy", "tob", "minutes");
                            }}
                            onChange={(e) => {
                              handleInputChange(e, "boy", "tob", "minutes");
                              setErrors((prev) => ({ ...prev, boyTOB: "" }));
                            }}
                          />
                        </div>
                        {errors.boyTOB && (
                          <div>
                            <small style={{ color: "red" }}>
                              {errors.boyTOB}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Girl's Details */}
                    <div className="col-lg-6 py-2">
                      <div className="p-4 border rounded-lg border-dark">
                        <div className="flex justify-between">
                          <h4 className="mb-0">Girl's Details</h4>
                          <button
                            type="button"
                            className="btn btn-sm py-2.5 px-3 btn-primary"
                            onClick={() => {
                              setFormData({
                                ...formData,
                                girl: {
                                  name: "",
                                  placeOfBirth: "",
                                  dob: { day: "", month: "", year: "" },
                                  tob: {
                                    hours: "",
                                    minutes: "",
                                    period: "AM",
                                  },
                                },
                              });
                              setData({});
                              setErrors({});
                              setInputValueGirl({
                                place: "",
                                dst: "",
                                latitude: "",
                                longitude: "",
                              });
                            }}
                          >
                            Reset
                          </button>
                        </div>

                        {/* Name */}
                        <label>Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder="Name"
                          tabIndex="8"
                          value={formData.girl.name}
                          readOnly={data.pdflink}
                          onChange={(e) => {
                            handleInputChange(e, "girl", "name");
                            setErrors((prev) => ({ ...prev, girlName: "" }));
                          }}
                        />
                        {errors.girlName && (
                          <div>
                            <small style={{ color: "red" }}>
                              {errors.girlName}
                            </small>
                          </div>
                        )}

                        {/* Place of Birth */}
                        <label>Place of birth</label>
                        <div className="relative">
                          <input
                            type="text"
                            className={`form-control ${
                              dropDownGirl &&
                              "rounded-t-lg rounded-ee-none rounded-es-none border-bottom-0 form-control"
                            }`}
                            name="place"
                            tabIndex="9"
                            placeholder="Place of Birth"
                            value={inputValuesGirl.place}
                            readOnly={data.pdflink}
                            onChange={(e) => {
                              searchPlaceGirl(e);
                              handleChangeGirl(e);
                              setErrors((prev) => ({
                                ...prev,
                                girlPlaceOfBirth: "",
                              }));
                            }}
                            autoComplete="off"
                          />
                          {dropDownGirl && (
                            <div
                              style={{ border: "1px solid #929699" }}
                              className="rounded-b-lg rounded-t-0 border-top-0 absolute bg-white z-10 w-full"
                            >
                              <div className="h-[200px] overflow-scroll mb-0">
                                {placeListGirl &&
                                  placeListGirl.map((val, index) => (
                                    <button
                                      className="list-none hover:bg-gray-300 cursor-pointer btn-block text-start px-2 flex"
                                      key={index}
                                      role="button"
                                      onClick={() => selectPlaceGirl(val)}
                                    >
                                      {val.placeName && (
                                        <div className="placeName">
                                          {val.placeName}
                                        </div>
                                      )}
                                      <div className="placeName">{"-"}</div>
                                      {val.StateName && (
                                        <div className="StateName">
                                          {val.StateName}
                                        </div>
                                      )}
                                      {val.StateName && (
                                        <div className="placeName">{"-"}</div>
                                      )}
                                      <div className="countryName">
                                        {val.countryName}
                                      </div>
                                    </button>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>

                        {errors.girlPlaceOfBirth && (
                          <div className="row">
                            <div className="col-12">
                              <small style={{ color: "red" }}>
                                {errors.girlPlaceOfBirth}
                              </small>
                            </div>
                          </div>
                        )}

                        {/* DOB */}
                        <label>DOB</label>
                        <div className="flex" style={{ gap: 10 }}>
                          <input
                            type="text"
                            className="form-control"
                            name="day"
                            placeholder="Day"
                            tabIndex="10"
                            value={formData.girl.dob.day}
                            readOnly={data.pdflink}
                            onBlur={(e) => {
                              addZeros(e, "girl", "dob", "day");
                            }}
                            onChange={(e) => {
                              handleInputChange(e, "girl", "dob", "day");
                              setErrors((prev) => ({ ...prev, girlDOB: "" }));
                            }}
                          />
                          <input
                            type="text"
                            className="form-control"
                            name="month"
                            placeholder="Month"
                            tabIndex="10"
                            value={formData.girl.dob.month}
                            readOnly={data.pdflink}
                            onBlur={(e) => {
                              addZeros(e, "girl", "dob", "month");
                            }}
                            onChange={(e) => {
                              handleInputChange(e, "girl", "dob", "month");
                              setErrors((prev) => ({ ...prev, girlDOB: "" }));
                            }}
                          />
                          <input
                            type="text"
                            className="form-control"
                            name="year"
                            placeholder="Year"
                            tabIndex="11"
                            value={formData.girl.dob.year}
                            readOnly={data.pdflink}
                            onBlur={(e) => {
                              addZeros(e, "girl", "dob", "year");
                            }}
                            onChange={(e) => {
                              handleInputChange(e, "girl", "dob", "year");
                              setErrors((prev) => ({ ...prev, girlDOB: "" }));
                            }}
                          />
                        </div>
                        {errors.girlDOB && (
                          <div>
                            <small style={{ color: "red" }}>
                              {errors.girlDOB}
                            </small>
                          </div>
                        )}

                        {/* TOB */}
                        <label className="mt-1">TOB</label>
                        <div className="flex" style={{ gap: 10 }}>
                          <input
                            type="number"
                            className="form-control"
                            name="hours"
                            tabIndex="12"
                            placeholder="Hours"
                            value={formData.girl.tob.hours}
                            readOnly={data.pdflink}
                            onBlur={(e) => {
                              addZeros(e, "girl", "tob", "hours");
                            }}
                            onChange={(e) => {
                              handleInputChange(e, "girl", "tob", "hours");
                              setErrors((prev) => ({ ...prev, girlTOB: "" }));
                            }}
                          />
                          <input
                            type="number"
                            className="form-control"
                            name="minutes"
                            tabIndex="13"
                            placeholder="Minutes"
                            value={formData.girl.tob.minutes}
                            readOnly={data.pdflink}
                            onBlur={(e) => {
                              addZeros(e, "girl", "tob", "minutes");
                            }}
                            onChange={(e) => {
                              handleInputChange(e, "girl", "tob", "minutes");
                              setErrors((prev) => ({ ...prev, girlTOB: "" }));
                            }}
                          />
                        </div>
                        {errors.girlTOB && (
                          <div>
                            <small style={{ color: "red" }}>
                              {errors.girlTOB}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Submit Button */}
                  <div className="row">
                    <div
                      className="col-12 py-2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {!data.pdflink ? (
                        <>
                          
                          
                          <button
                            // type="submit"
                            onClick={(e) => fetchMatchmakingData(e)}
                            className="btn btn-sm py-2.5 px-3"
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <button
                          // type="submit"
                          onClick={(e) => clear(e)}
                          className="btn btn-sm py-2.5 px-3"
                          disabled
                        >
                          Submit
                        </button>
                      )}
                      {data.pdflink ? (
                        <div>
                          <a
                            href={data.pdflink}
                            role="button"
                            className="btn btn-sm py-2.5 px-3"
                            target="_blank"
                          >
                            {`Download `} <i className="fa fa-download"></i>
                          </a>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: tabDisabled ? "" : "none",
                            background: "none",
                          }}
                        >
                          <button
                            disabled
                            type="button"
                            class="ml-2 mt-1 py-2 px-2 me-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center"
                          >
                            <svg
                              aria-hidden="true"
                              role="status"
                              class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="#1C64F2"
                              />
                            </svg>
                            Loading...
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Card>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Matchmaking;
