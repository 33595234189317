import Suggestion from "views/pages/Suggestion";
import NewQuery from "views/pages/NewQuery";
import Dashboard from "views/Dashboard";
import Profile from "views/pages/Profile";
import Questionanswer from "views/pages/Questionanswer";
import Matchmaking from "views/pages/Matchmaking";


var allroutes = [
  
  {
    path: "/addnewquery/",
    name: "Add New Query",
    // props:"id",  
    icon: "tim-icons icon-chart-pie-36",
    component: Suggestion,
    layout: "/acharya"
  },
  {
    path: "/questions",
    name: "All Questions",
    icon: "tim-icons icon-chart-pie-36",
    component: Questionanswer,
    layout: "/acharya"
  },
  {
    path: "/match-making",
    name: "Match Making",
    icon: "tim-icons icon-chart-pie-36",
    component: Matchmaking,
    layout: "/acharya"
  },
  // {
  //   path: "/registration",
  //   name: "registration",
  //   icon: "tim-icons icon-chart-pie-36",
  //   component: Registration,
  //   layout: "/acharya"
  // },
  {
    path: "/support/",
    name: "Support",
    props:"id",    
    icon: "tim-icons icon-chart-pie-36",
    component: NewQuery,
    layout: "/acharya"
  },
  {
    path: "/view-reply/:id",
    name: "View Reply",
    props:"id",    
    icon: "tim-icons icon-chart-pie-36",
    component: NewQuery,
    layout: "/acharya"
  },
  {
    path: "/myprofile",
    name: "Profile",
    // mini: "D",
    icon: "tim-icons icon-single-02",
    component: Profile,
    layout: "/acharya"
  }

  
];
export default allroutes;
