import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import defaultImage from "../../assets/img/logo.png";
import defaultAvatar from "../../assets/img/logo.png";
import axios from "axios";
// import {uploadFile} from "react-s3"
import ReactS3 from 'react-s3'
import AWS, { CloudFormation }  from "aws-sdk";
import { data } from "autoprefixer";
import NotificationAlert from "react-notification-alert";
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;
window.Buffer = window.Buffer || require("buffer").Buffer; 

const S3_BUCKET ='';
// const REGION ='YOUR_REGION_NAME';
const ACCESS_KEY ='';
const SECRET_ACCESS_KEY ='';


// AWS.config.update({
//   accessKeyId: ACCESS_KEY,
//   secretAccessKey: SECRET_ACCESS_KEY,
//   region: 'us-east-1',
//   signatureVersion: 'v4',
// });

const ImageUpload = ({
  avatar,
  addBtnColor,
  addBtnClasses,
  changeBtnColor,
  changeBtnClasses,
  removeBtnColor,
  removeBtnClasses,
  imageUrl,
  src
}) => {
  const acharya = localStorage.getItem("user");
  const userDetail = JSON.parse(acharya);
  let aid = userDetail.id;
  let aname = userDetail.name;
  let acharyaimg = userDetail.image;
  console.log("Image is===",acharyaimg);
  let [file, setFile] = React.useState(null);
  // const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
  //   avatar ? defaultAvatar : defaultImage
  // );

  console.log("Default image avtar image upload=====",defaultAvatar);
  console.log("image avatar=====",avatar);
  console.log("acharyaimg====",acharyaimg);
  let [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    acharyaimg ? acharyaimg : defaultAvatar
  );
  imagePreviewUrl = file ? imagePreviewUrl :(acharyaimg ? acharyaimg : defaultAvatar);
  const notificationAlertRef = React.useRef(null);
  const fileInput = React.useRef(null);
  const handleImageChange = (e) => {
    
    e.preventDefault();
    let reader = new FileReader();
    let file =e.target.files[0];    
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
      imageUrl(reader.result)
    };
    reader.readAsDataURL(file);
  };
  
  // eslint-disable-next-line
  const handleSubmit = async(e) => {
    //const s3 = new AWS.S3();
    // e.preventDefault();
    // alert(file.name);
    // const obj = {
    //   "image":imagePreviewUrl 
    // }    
    const config = {
        bucketName: S3_BUCKET,     
        region: 'ap-south-1',
        dirName: 'checkhoro',
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
    }
  
    console.log("Upload file image name ==",file.name);   
    let imgtype = file.name.split(".")
    console.log("image type==",imgtype[1])
    let newname= aid+"_"+aname+"."+imgtype[1];

    console.log("fdsfsfsff",newname, imgtype)
    //file.name=setFile(newname);
    const result = await ReactS3.uploadFile(file, config)
    //   
   
    imagePreviewUrl = "https://astroscienceprediction.s3.ap-south-1.amazonaws.com/checkhoro/"+file.name
    setImagePreviewUrl("https://astroscienceprediction.s3.ap-south-1.amazonaws.com/checkhoro/"+file.name)       

      console.log("Result data==",result);
      if(result){       
        const objdata = {
          id:aid,
          image:"https://astroscienceprediction.s3.ap-south-1.amazonaws.com/checkhoro/"+file.name
        }
        let resultdata = await axios.post(`${BAS_URL_APPAPI}acharya/updateimg`,objdata)
        console.log("kldslfkjflsjfkd",resultdata);
        setFile(null)
        window.location.reload(false);
        let notifyMessage = "Image uploaded successfully"; 
            let optiondata = {};
              optiondata = {
              place: "tc",
              message: notifyMessage,
              type: "primary",
              icon: "tim-icons icon-bell-55",
              autoDismiss: 7
              };
              notificationAlertRef.current.notificationAlert(optiondata);
            //alert("Image uploaded successfully");
      }
      
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
  };

 


  return (
    
    <div className="fileinput text-center">
      <NotificationAlert ref={notificationAlertRef} />
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
        <img src={src} alt="..." />  
      </div>
      <div>
        {file === null ? (
          <Button
            color={addBtnColor}
            className={addBtnClasses}
            onClick={() => handleClick()}
          >
            {avatar ? "Add Photo" : <i className="fa fa-edit" />}
          </Button>
        ) : (
          <span>
            {/* <Button
              color={changeBtnColor}
              className={changeBtnClasses}
              onClick={() => handleClick()}
            >
              <i className="fa fa-upload" />
            </Button> */}
            {/* <Button
              color={changeBtnColor}
              className={changeBtnClasses}
              onClick={() => handleSubmit()}
            >
              <i className="fa fa-upload" />
            </Button> */}
            {avatar ? <br /> : null}
            {/* <Button
              color={removeBtnColor}
              className={removeBtnClasses}
              onClick={() => handleRemove()}
            >
              <i className="fa fa-times" /> 
            </Button> */}
            <Button
            color={addBtnColor}
            className={addBtnClasses}
            onClick={() => handleClick()}
          >
            {avatar ? "Add Photo" : <i className="fa fa-edit" />}
          </Button>
          </span>
        )}
      </div>
    </div>
  );
};

ImageUpload.defaultProps = {
  avatar: false,
  removeBtnClasses: "btn-round",
  removeBtnColor: "danger",
  addBtnClasses: "btn-round",
  addBtnColor: "primary",
  changeBtnClasses: "btn-round",
  changeBtnColor: "primary"
};

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  removeBtnClasses: PropTypes.string,
  removeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  addBtnClasses: PropTypes.string,
  addBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ]),
  changeBtnClasses: PropTypes.string,
  changeBtnColor: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "link"
  ])
};

export default ImageUpload;
