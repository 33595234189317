import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import Parser from "html-react-parser";
import { useLocation } from "react-router-dom";
import {
  ButtonGroup,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Horoinnertwo from "components/Dashboard/Horoinnertwo";
import Horoinnerthree from "components/Dashboard/Horoinnerthree";
import Mahadasha from "components/Dashboard/Mahadasha";
import Subhasubh from "components/Dashboard/Subhasubh";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Row, Col, Form } from "reactstrap";
import { getGocharKundali } from "redux/actions/horoscope";
import { getInitialData } from "redux/actions/horoscope";
import debounce from "lodash.debounce";
import "../assets/css/horoinnerone.css";
import { Threedotscomp } from "components/Spinner/Threedotscomp";
import { selectedlanguage } from "redux/actions/horoscope";
import { useHistory } from "react-router-dom";
import { Threedotscomppred } from "components/Dashboard/Threedotscomppred";
import moment from "moment";
import NotificationAlert from "react-notification-alert";
import { HOROSCOPE_POST } from "redux/actions/constants";
import { number, string } from "prop-types";
import { getGocharKundaliInc } from "redux/actions/horoscope";
import { logout } from "redux/actions/auth";
import { transform } from "typescript";
import { checkplan } from "redux/actions/plan";
import { CHECK_PLAN } from "redux/actions/constants";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

// env data
const email = process.env.REACT_APP_CHECK_EMAIL;
const password = process.env.REACT_APP_CHECK_PASS;
const BAS_URL_APPAPI = process.env.REACT_APP_BAS_URL_APPAPI;
const VASHISHT_API_URL = process.env.REACT_APP_VASHISHT_API;

const Dashboard = () => {
  const history = useHistory();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [questionmodal, setQuestionmodal] = useState(false);
  const [modalvideo, setModalVideo] = useState(false);
  const [modalSearch, setModalSearch] = useState(false);
  const [notification, setNotification] = useState();
  const [loader, setLoader] = useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function seModalVideo() {
    // console.log("EFGH");
    setModalVideo(false);
  }

  function closeModalSearch() {
    setModalSearch(false);
  }

  function closeModal() {
    setIsOpen(false);
    setQuestionmodal(false);
    seModalVideo(false);
  }

  const dispatch = useDispatch();
  const products = useSelector((state) => state.product);
  const allData = useSelector((state) => state.horoscope);

  const questionans = useSelector((state) => state.question);
  const [show, setShow] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [placeList, setPlaceList] = useState([]);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [gender, setGender] = useState("male");
  const [kundalitype, setKundalitype] = useState("kp");
  const [birthDate, setBirthDate] = useState({ day: "", month: "", year: "" });
  const [timezone, setTimezone] = useState();
  const [language, setLanguage] = useState("");
  const [languageList, setLanguageList] = useState([]);
  const [uname, setName] = useState("");
  const [dst, setDST] = useState(false);
  const [generate, setGenerate] = useState(true);
  const [currentPlace, setCurrentPlace] = useState({
    StateName: "",
    countryName: "",
    districtName: "",
    latitude: "",
    longitude: "",
    placeName: "",
    timezone: timezone,
    dstoffset: "5.5",
    gmtoffset: "5.5",
  });
  // const [birthTime, setBirthTime] = useState({hour: "10", minute: "10"})
  const [birthTime, setBirthTime] = useState("");
  const [rotatekundali, setRotateKundali] = useState("");
  const [productlist, setProductlist] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [showpdf, setShowPdf] = useState(false);
  const [showref, setShowRef] = useState(false);
  const [showupay, setShowUpay] = useState(false);
  const [showgochar, setShowGochar] = useState(false);
  const [ageRange, setAgeRange] = useState({ fromAge: "", toAge: "" });
  const [userdetails1, setUserDetails] = useState({
    dob: { day: "", month: "", year: "" },
    tob: { hour: "", minute: "" },
    name: "",
    pob: "",
    relation: "",
    gender: "male",
  });
  const [place, setPlace] = useState("");
  const [dropDown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isQuLoading, setQuIsLoading] = useState(false);
  const [ispedLoading, setispedLoading] = useState(false);
  const [pred, setPred] = useState();

  const [remark, setRemark] = useState();
  const [savedkundalidata, setSavedKundali] = useState();
  const [pcategory, setPcategory] = useState();
  const [ppaid, setPpaid] = useState();
  const [incrementmin, setIncrement] = useState(1);
  const [decrementmin, setDecrement] = useState(0);
  const [branding, setBranding] = useState();
  const [dayhoursmin, setDayHoursmin] = useState("0");
  const [validity, setValidity] = useState();
  const [questionasn, setQuestionans] = useState();
  const [badquestionasn, setBadQuestionans] = useState();
  const [childquestionasn, setChildQuestionans] = useState();
  const [foreignquestionasn, setForeignQuestionans] = useState();
  const [goodquestionasn, setHGoodQuestionans] = useState();
  const [healthquestionasn, setHealthQuestionans] = useState();
  const [chartquestionasn, setChartQuestionans] = useState();
  const [lovequestionasn, setLoveQuestionans] = useState();
  const [occupationquestionasn, setOccupationQuestionans] = useState();
  const [parrentquestionasn, setParrentQuestionans] = useState();
  const [vehiclequestionasn, setVehicleQuestionans] = useState();
  const [marriagequestionasn, setMarriageQuestionans] = useState();
  const [isleapYear, setIsLeapYear] = useState("");
  const [alldatatrue, setAlldatatrue] = useState(
    !(Object.keys(allData.horo).length === 0)
  );
  const [activeutton, setActivebutton] = useState(false);
  const [gocharShodhit, setGocharShodhit] = useState([]);
  const [isGocharSelected, setIsGocharSelected] = useState(false);

  const notificationAlertRef = React.useRef(null);
  const myRef = useRef(null);
  const scrollDiv = () => myRef.current.scrollIntoView();

  const rotatekundaliList = [
    { id: 1, name: "Your Self" },
    { id: 2, name: "Father in law" },
    { id: 3, name: "younger Brother/Sister" },
    { id: 4, name: "Mother" },
    { id: 5, name: "Father/Son" },
    { id: 6, name: "Mama/Bhanja/Bua/Brother in Law(Saala)" },
    { id: 7, name: "Spouse" },
    { id: 9, name: "Daada Ji" },
    { id: 10, name: "Chacha" },
    { id: 11, name: "Elder brother/Sister/Taau" },
  ];

  const productList = [
    { id: 150, name: "Vashist Jyotish 1 Year" },
    { id: 82, name: "All Occupation" },
    { id: 25, name: "Health" },
    { id: 42, name: "House Vehicle" },
    { id: 38, name: "Married Life" },
    { id: 40, name: "Child" },
    { id: 36, name: "Good Wealth" },
    { id: 250, name: "Bad Wealth " },
    { id: 44, name: "Foreign Trip" },
  ];

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    // console.log("USER", user);
    if (user && user.count && user.count < 3) {
      localStorage.setItem("skip", true);
    }
  }, []);
  const toggleModal = () => {
    // console.log("ABCDEF");
    seModalVideo(!modalvideo);
  };

  let localkundali = localStorage.getItem("kundalidata");
  if (!localkundali) {
    let dataObj = {
      langitutde: "",
      gender: "",
      kundalitype: "",
      birthDate: {
        day: "",
        month: "",
        year: "",
      },
      timezone: "",
      language: "1",
      product: "150",
      latitude: "",
      name: "",
      dst: "",
      generate: "",
      pob: {
        placeName: "",
        StateName: "",
        countryName: "",
        latitude: "",
        longitude: "",
        gmtoffset: "",
        dstoffset: "",
        timezone: "",
      },
      birthTime: {
        hour: "",
        minute: "",
      },
      rotatekundali: "1",
      currentDate: "",
      currentTime: "",
      showpdf: true,
      showgochar: true,
      ageRange: "",
      branding: "",
      acharyaid: "",
      btntype: "prediction",
      message: "",
      generateKundaliProduct: "",
    };
    localkundali = JSON.stringify(dataObj);
    localStorage.setItem("kundalidata", JSON.stringify(dataObj));
  }
  let localkundalidata = localkundali ? JSON.parse(localkundali) : "";
  const debouncedSearch = useCallback(
    debounce((nextValue) => searchPlaceAPI(nextValue), 500),
    [] // will be created only once initially
  );

  ///newjbdjsj

  const clearQuestionAnswers = () => {
    const allQuesCategories = JSON.parse(localStorage.getItem("cat_id"));
    if (allQuesCategories) {
      allQuesCategories.forEach((key) => localStorage.removeItem(key));
    }
  };
  const getLanguages = async () => {
    // const result = await axios.post(`${BAS_URL_APPAPI}acharya/getcredt`);
    const usertoken = localStorage.getItem("vjtoken");
    const vuserid = acharyaid;
    const config = {
      headers: {
        authorization: "Bearer " + usertoken,
        "Content-Type": "application/json",
      },
    };
    let res = await axios.get(`${VASHISHT_API_URL}language`, config);
    if (res.data["success"] === 0 && res.data["messsge"] === "Invalid token") {
      // const result = await axios.post(`${BAS_URL_APPAPI}acharya/getcredt`);
      const usertoken = localStorage.getItem("vjtoken");
      const config = {
        headers: {
          authorization: "Bearer " + usertoken,
          "Content-Type": "application/json",
        },
      };
      res = await axios.get(`${VASHISHT_API_URL}language`, config);
    }
    setLanguageList(res.data.data);
  };

  const searchPlaceAPI = async (text) => {
    let token = localStorage.getItem("token");
    try {
      // const result = await axios.post(`${BAS_URL_APPAPI}acharya/getcredt`);
      const usertoken = localStorage.getItem("vjtoken");
      if (text.length > 0) {
        const body = { district: text };
        const requestOptions = {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + usertoken,
          },
        };
        let body1 = JSON.stringify(body);
        let res = await axios.post(
          `${VASHISHT_API_URL}place/search-place`,
          body1,
          requestOptions
        );
        setPlaceList(res.data.data);
      } else {
        setPlaceList();
      }
    } catch (err) {}
  };
  const searchPlace = (e) => {
    const { value: nextValue } = e.target;
    if (e.target.value === "") {
      setShowDropdown(false);
    } else {
      setShowDropdown(true);
    }
    localkundalidata.pob.placeName = "";
    localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
    setPlace(e.target.value);
    debouncedSearch(nextValue);
  };

  const getData = () => {
    dispatch(getInitialData());
  };
  const nameChange = (e) => {
    localkundalidata.name = "";
    localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
    setName(e.target.value);
    setUserDetails({ ...userdetails1, name: e.target.value });
  };
  // //console.log("initial data====",getData());
  const selectPlace = (val) => {
    setCurrentPlace(val);
    localkundalidata.pob.placeName = "";
    localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
    setPlace(val.placeName);
    setShowDropdown(false);
    localkundalidata.pob.longitude = "";
    localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
    setLongitude(val.longitude.toString());
    localkundalidata.pob.latitude = "";
    localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
    setLatitude(val.latitude.toString());
    localkundalidata.pob.timezone = "";
    localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
    setTimezone(val.timezone.toString());
    setUserDetails({ ...userdetails1, pob: val });
    setInputValue({
      ...inputValues,
      pob: val,
      place: val.placeName.toString(),
      latitude: val.latitude.toString(),
      longitude: val.longitude.toString(),
    });
  };

  const dayChange = (e) => {
    localkundalidata.birthDate.day = "";
    localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
    setUserDetails({
      ...userdetails1,
      dob: { ...userdetails1.dob, day: e.target.value },
    });
    setBirthDate({
      ...birthDate,
      month: localkundalidata.birthDate.month
        ? localkundalidata.birthDate.month
        : e.target.value,
    });
  };

  const monthChange = (e) => {
    localkundalidata.birthDate.month = "";
    localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
    setUserDetails({
      ...userdetails1,
      dob: { ...userdetails1.dob, month: e.target.value },
    });
    setBirthDate({
      ...birthDate,
      month: localkundalidata.birthDate.month
        ? localkundalidata.birthDate.month
        : e.target.value,
    });
  };

  // console.log(isleapYear, "isleapYear");

  const yearChange = (e) => {
    localkundalidata.birthDate.year = "";
    localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
    setUserDetails({
      ...userdetails1,
      dob: { ...userdetails1.dob, year: e.target.value },
    });
    setBirthDate({
      ...birthDate,
      year: localkundalidata.birthDate.year
        ? localkundalidata.birthDate.year
        : e.target.value,
    });
  };

  const hourChange = (e) => {
    const limit = 2;
    // 👇️ only take first N characters
    let hour = Number(e.target.value.slice(0, limit));
    if (hour < 24) e.target.value = hour + "";
    else hour = e.target.value = e.target.value.slice(0, 1);
    handleChange(e);
    localkundalidata.birthTime.hour = hour + "";
    localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
    setUserDetails({ ...userdetails1, tob: { hour: e.target.value } });
  };

  const minuteChange = (e) => {
    const limit = 2;
    // 👇️ only take first N characters
    let min = Number(e.target.value.slice(0, limit));
    if (min < 60) e.target.value = min + "";
    else min = e.target.value = e.target.value.slice(0, 1);
    handleChange(e);
    localkundalidata.birthTime.minute = min + "";
    localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
    setUserDetails({
      ...userdetails1,
      tob: { hour: birthTime.hour, minute: e.target.value },
    });
  };
  const tobChange = (e) => {
    setBirthTime(e.target.value);
  };

  const setMale = (e) => {
    if (!e.target.value) {
      setGender("female");
      setInputValue({ ...inputValues, gender: "female" });
    } else {
      setGender("male");
      setInputValue({ ...inputValues, gender: "male" });
    }
  };

  const setFemale = (e) => {
    if (!e.target.value) {
      setGender("male");
    } else {
      setGender("female");
    }
  };

  const kundalitypeChange = (e) => {
    setKundalitype(e.target.value);
  };

  const pdfChange = (e) => {
    if (e.target.value === "on") {
      setShowPdf(true);
    } else {
      setShowPdf(false);
    }
  };

  const goCharChange = (e) => {
    if (e.target.value === "on") {
      setShowGochar(true);
    } else {
      setShowGochar(false);
    }
  };

  const upayChange = (e) => {
    if (e.target.value === "on") {
      setShowUpay(true);
    } else {
      setShowUpay(false);
    }
  };

  const languageSelect = (e) => {
    dispatch(selectedlanguage(e.target.value));
    setLanguage(e.target.value);
  };

  const changeRemark = (e) => {
    setRemark(e.target.value);
  };

  const [inputValues, setInputValue] = useState({
    uname: localkundalidata.name + "",
    place: localkundalidata.pob.placeName + "",
    day: localkundalidata.birthDate.day + "",
    month: localkundalidata.birthDate.month + "",
    year: localkundalidata.birthDate.year + "",
    hours: localkundalidata.birthTime.hour + "",
    minutes: localkundalidata.birthTime.minute + "",
    product: localkundalidata.product + "",
    language: localkundalidata.language + "",
    predfor: localkundalidata.rotatekundali + "",
    female: localkundalidata.gender + "",
    male: localkundalidata.gender + "",
    dst: "",
    gdvashist: "",
    Self: "",
    latitude: localkundalidata.latitude + "",
    longitude: localkundalidata.langitutde + "",
    pob: localkundalidata.pob,
  });
  let days = inputValues.day;
  let months = inputValues.month;
  let hours = inputValues.hours;
  let minutes = inputValues.minutes;

  let acharya = localStorage.getItem("user");

  if (!acharya) {
    dispatch(logout());
    history.push("/login");
    localStorage.clear();
  }
  const userDetail = acharya ? JSON.parse(acharya) : null;
  const acharyaname = userDetail ? userDetail["name"] : "";
  const acharyaid = userDetail ? userDetail["id"] : "";
  let allDatanew;
  const Submit = async (e) => {
    // const result = await axios.post(`${BAS_URL_APPAPI}acharya/apitoken`);
    const usertoken = localStorage.token;
    let acharya = localStorage.getItem("user");
    const userDetail = JSON.parse(acharya);
    const acharyaname = userDetail["name"];
    const acharyaid = userDetail["id"];
    setIsLoading(true);
    let dateObj = new Date();
    let output = "";
    let month = dateObj.getMonth() + 1;
    let day = String(dateObj.getDate()).padStart(2, "0");
    let year = dateObj.getFullYear();
    let currentime =
      (dateObj.getHours() < 10 ? "0" : "") +
      dateObj.getHours() +
      ":" +
      (dateObj.getMinutes() < 10 ? "0" : "") +
      dateObj.getMinutes();
    setCurrentTime(currentime);
    let currentdate;
    if (month < 10) {
      //   output = day + '/' + '0' + month + '/' + year;
      currentdate = day + "/" + "0" + month + "/" + year;
      setCurrentDate(currentdate);
    } else {
      //   output = day + '/' + month + '/' + year;
      currentdate = day + "/" + month + "/" + year;
      setCurrentDate(currentdate);
    }
    // console.log("timezonetimezonetimezone", timezone);
    let dataObj = {
      langitutde: inputValues.longitude,
      gender: gender,
      kundalitype: kundalitype,
      birthDate: {
        day: inputValues.day,
        month: inputValues.month,
        year: inputValues.year,
      },
      timezone: inputValues.pob.timezone,
      language: inputValues.language,
      product: inputValues.product,
      latitude: inputValues.latitude,
      name: inputValues.uname,
      dst: dst,
      generate: generate,
      pob: inputValues.pob,
      birthTime: {
        hour: inputValues.hours,
        minute: inputValues.minutes,
      },
      rotatekundali: inputValues.predfor,
      currentDate: currentdate,
      currentTime: currentime,
      showpdf: showpdf,
      showgochar: showgochar,
      ageRange: ageRange,
      acharyaid: acharyaid,
      btntype: "viewkundali",
      finalyear: finalyear,
      message: "",
      generateKundaliProduct: "",
      token: usertoken,
    };
    //localstorage
    localStorage.setItem("kundalidata", JSON.stringify(dataObj));
    viewKundali(dataObj);
  };

  let insertedId;

  const viewKundali = async (dataObj) => {
    // console.log(yearsBetween, `${localkundalidata.birthDate.year}-${localkundalidata.birthDate.month}-${localkundalidata.birthDate.day}`, finalyear, "njjjnjnj")
    setIsLoading(true);
    try {
      const res = await axios.post(
        `${BAS_URL_APPAPI}acharya/generatekundali`,
        dataObj,
        userdetails1
      );
      allDatanew = res.data.data;
      if (allDatanew) {
        const data = await dispatch(
          getGocharKundali(allDatanew, dataObj, userdetails1, finalyear)
        );
        insertedId = res.data.data.insertId;
        //remove questionanswer from local storage//
        clearQuestionAnswers();
        if (allData) {
          let body = {
            language1: language,
            pdflink: "",
            productName: productlist,
            vcnno: "",
            name: allData.horo.new_userDEtails[0].name,
            gender1: allData.horo.new_userDEtails[0].gender,
            dob: allData.horo.new_userDEtails[0].dob,
            pob: allData.horo.new_userDEtails[0].locaton,
            tob: allData.horo.new_userDEtails[0].tob,
            mobile: "",
            acharyaid: acharyaid,
            kundli_for: rotatekundali,
            acharyaname: acharyaname,
          };
          setAlldatatrue(true);

          // try {
          //   let savesetails = await axios.post(
          //     `${BAS_URL_APPAPI}acharya/savekundalicredit`,
          //     body
          //   );
          //   if (savesetails.data.success === 1) {
          //     setispedLoading(false);
          //     setIsLoading(false);
          //   } else {
          //     alert("Kundali Not saved");
          //   }
          // } catch (error) {}
        }
        if (getGocharKundali) {
          setIsLoading(false);
        }
      } else {
        alert("skdhlffhsklfhfslfkhfsfsflks");
      }
    } catch (error) {
      dispatch(checkplan());
    }
  };

  const saveData = async () => {
    let notifyMessage = "Remarks Saved Successfully";
    let dateObj = new Date();
    let output = "";
    let month = dateObj.getMonth() + 1;
    let day = String(dateObj.getDate()).padStart(2, "0");
    let year = dateObj.getFullYear();
    let currentime =
      (dateObj.getHours() < 10 ? "0" : "") +
      dateObj.getHours() +
      ":" +
      (dateObj.getMinutes() < 10 ? "0" : "") +
      dateObj.getMinutes();
    setCurrentTime(currentime);
    let currentdate;
    if (month < 10) {
      //   output = day + '/' + '0' + month + '/' + year;
      currentdate = day + "/" + "0" + month + "/" + year;
      setCurrentDate(currentdate);
    } else {
      //   output = day + '/' + month + '/' + year;
      currentdate = day + "/" + month + "/" + year;
      setCurrentDate(currentdate);
    }
    let user = localStorage.getItem("user");
    let token = localStorage.getItem("token");
    const userDetail = JSON.parse(user);
    const userid = userDetail["id"];
    // console.log("Acharya Id=====", userid);
    let dataObj = {
      langitutde: inputValues.longitude,
      gender: gender,
      kundalitype: kundalitype,
      birthDate: {
        day: inputValues.day,
        month: inputValues.month,
        year: inputValues.year,
      },
      timezone: inputValues.pob.timezone,
      language: inputValues.language,
      product: inputValues.product,
      latitude: inputValues.latitude,
      name: inputValues.uname,
      dst: dst,
      generate: generate,
      pob: inputValues.pob,
      birthTime: {
        hour: inputValues.hours,
        minute: inputValues.minutes,
      },
      rotatekundali: inputValues.predfor,
      currentDate: currentdate,
      currentTime: currentime,
      showpdf: showpdf,
      showgochar: showgochar,
      ageRange: ageRange,
      remark: remark,
      acharyaID: userid,
      btntype: "viewkundali",
      finalyear: finalyear,
    };

    const bodyParameters = {
      id: userid,
      token: token,
    };

    try {
      const savedata = await axios.post(
        `${BAS_URL_APPAPI}acharya/kundalistore`,
        dataObj,
        bodyParameters
      );

      if (savedata) {
        let optiondata = {};
        optiondata = {
          place: "tc",
          message: notifyMessage,
          type: "primary",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(optiondata);
        setIsOpen(false);
        setRemark("");
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response.data.messages == "NOT_A_USER") {
        dispatch(logout());
      }
    }
  };

  const handleBranding = async (e) => {
    setBranding(e.target.value);
  };
  const setNewDhm = async (e) => {
    setDayHoursmin(e);
  };

  const updatetob = async (value) => {
    if (value === 0) {
      alert("you can not select value less than 1");
      return false;
    }
    setIncrement(value);
    setDecrement(value);
    return;

    let notifyMessage = "";
    if (dayhoursmin == "day") {
      days = parseInt(days) + parseInt(value);
      //Days
      setUserDetails({
        ...userdetails1,
        dob: { ...userdetails1.dob, day: days },
      });
      if (days < 32 && days > 0) {
        //setBirthDate
        setInputValue({ ...inputValues, day: days + "" });
      } else {
        setInputValue({ ...inputValues, day: "01" });
        setInvalid(true);
      }
    } else if (dayhoursmin == "hour") {
      hours = parseInt(hours) + parseInt(value);
      if (hours < 24) {
        //Increment,hours)
        setInputValue({ ...inputValues, hours: hours + "" });
        //console.log,Birth hours
      } else {
        alert("Please select value less than 24");
      }
    } else {
      minutes = parseInt(minutes) + parseInt(value);
      setInputValue({ ...inputValues, minutes: minutes + "" });
    }
    setIsLoading(true);
    let dateObj = new Date();
    let output = "";
    let month = dateObj.getMonth() + 1;
    let day = String(dateObj.getDate()).padStart(2, "0");
    let year = dateObj.getFullYear();
    let currentime =
      (dateObj.getHours() < 10 ? "0" : "") +
      dateObj.getHours() +
      ":" +
      (dateObj.getMinutes() < 10 ? "0" : "") +
      dateObj.getMinutes();
    setCurrentTime(currentime);
    let currentdate;
    if (month < 10) {
      //   output = day + '/' + '0' + month + '/' + year;
      currentdate = day + "/" + "0" + month + "/" + year;
      setCurrentDate(currentdate);
    } else {
      //   output = day + '/' + month + '/' + year;
      currentdate = day + "/" + month + "/" + year;
      setCurrentDate(currentdate);
    }

    let dataObj = {
      langitutde: inputValues.longitude,
      gender: gender,
      kundalitype: kundalitype,
      birthDate: {
        day: inputValues.day,
        month: inputValues.month,
        year: inputValues.year,
      },
      timezone: inputValues.pob.timezone,
      language: inputValues.language,
      product: inputValues.product,
      latitude: inputValues.latitude,
      name: inputValues.uname,
      dst: dst,
      generate: generate,
      pob: inputValues.pob,
      birthTime: {
        hour: inputValues.hours,
        minute: inputValues.minutes,
      },
      rotatekundali: inputValues.predfor,
      currentDate: currentdate,
      currentTime: currentime,
      showpdf: showpdf,
      showgochar: showgochar,
      ageRange: ageRange,
      acharyaid: acharyaid,
      finalyear: finalyear,
    };
    const data = await dispatch(getGocharKundali(dataObj, userdetails1));

    if (getGocharKundali) {
      setIsLoading(false);
    }
  };

  const changeIncdate = async (e) => {
    if (
      inputValues.day == "" ||
      inputValues.hours == "" ||
      inputValues.minutes == ""
    )
      return;
    if (dayhoursmin == "0") return;
    let incrementminVal = incrementmin;
    setIncrement(incrementminVal);
    if (dayhoursmin == "day") {
      //day = birthDate.day;
      days = parseInt(days) + parseInt(incrementminVal);
      //console.log("Days===============",days);
      setUserDetails({
        ...userdetails1,
        dob: { ...userdetails1.dob, day: days },
      });
      if (days < 32 && days > 0) {
        inputValues.day = "" + days;
        localkundalidata.birthDate.day = days + "";
        localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
      } else {
        alert("Please select value less than 31");
        return;
      }
    } else if (dayhoursmin == "hour") {
      let newincrement = parseInt(incrementminVal);
      if (newincrement === 0) {
        alert("You can't enter value 0 ");
        return;
      } else {
        let hour = parseInt(hours) + parseInt(incrementminVal);
        if (hour < 24) {
          inputValues.hours = "" + hour;
          localkundalidata.birthTime.hour = hour + "";
          localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
          setUserDetails({
            ...userdetails1,
            dob: { ...userdetails1.dob, year: e.target.value },
          });
        } else {
          alert("Please select value less than 24");
          return;
        }
      }
    } else {
      let newincrement = parseInt(incrementminVal);
      if (newincrement === 0) {
        alert("You can't enter value 0 ");
        return;
      } else {
        minutes = parseInt(minutes) + parseInt(incrementminVal);

        if (minutes < 59) {
          inputValues.minutes = "" + minutes;
          localkundalidata.birthTime.minute = minutes + "";
          localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
          setInputValue({ ...inputValues, minutes: minutes + "" });
        } else {
          alert("Please select value less than 60");
          return;
        }
      }
    }

    setIsLoading(true);
    let dateObj = new Date();
    let output = "";
    let month = dateObj.getMonth() + 1;
    let day = String(dateObj.getDate()).padStart(2, "0");
    let year = dateObj.getFullYear();
    let currentime =
      (dateObj.getHours() < 10 ? "0" : "") +
      dateObj.getHours() +
      ":" +
      (dateObj.getMinutes() < 10 ? "0" : "") +
      dateObj.getMinutes();
    setCurrentTime(currentime);
    let currentdate;
    if (month < 10) {
      //   output = day + '/' + '0' + month + '/' + year;
      currentdate = day + "/" + "0" + month + "/" + year;
      setCurrentDate(currentdate);
    } else {
      //   output = day + '/' + month + '/' + year;
      currentdate = day + "/" + month + "/" + year;
      setCurrentDate(currentdate);
    }
    // console.log("Time zone in increment decremnt----", timezone);

    let dataObj = {
      langitutde: inputValues.longitude,
      gender: gender,
      kundalitype: kundalitype,
      birthDate: {
        day: inputValues.day,
        month: inputValues.month,
        year: inputValues.year,
      },
      timezone: inputValues.pob.timezone,
      language: inputValues.language,
      product: inputValues.product,
      latitude: inputValues.latitude,
      name: inputValues.uname,
      dst: dst,
      generate: generate,
      pob: inputValues.pob,
      birthTime: {
        hour: inputValues.hours,
        minute: inputValues.minutes,
      },
      rotatekundali: inputValues.predfor,
      currentDate: currentdate,
      currentTime: currentime,
      showpdf: showpdf,
      showgochar: showgochar,
      ageRange: ageRange,
      acharyaid: acharyaid,
      finalyear: finalyear,
    };
    const data = await dispatch(getGocharKundaliInc(dataObj, userdetails1));
    if (getGocharKundaliInc) {
      clearQuestionAnswers();
      setIsLoading(false);
    }
    updatetob(incrementminVal);
  };
  const changeDecdate = async (e) => {
    if (
      inputValues.day == "" ||
      inputValues.hours == "" ||
      inputValues.minutes == ""
    )
      return;
    if (dayhoursmin == "0") return;

    let incrementminVal = incrementmin;
    setDecrement(incrementminVal);
    let notifyMessage = "";
    if (dayhoursmin == "day") {
      //day = birthDate.day;
      days = parseInt(days) - parseInt(incrementminVal);
      //console.log("Days===============",days);
      setUserDetails({
        ...userdetails1,
        dob: { ...userdetails1.dob, day: days },
      });
      if (days < 32 && days > 0) {
        inputValues.day = "" + days;
        localkundalidata.birthDate.day = days + "";
        localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
      } else {
        alert("Please select value less than 31");
        return;
      }
    } else if (dayhoursmin == "hour") {
      let newincrement = parseInt(incrementminVal);
      //console.log("newincrementnewincrementnewincrement==0,", typeof(newincrement), newincrement)
      if (newincrement === 0) {
        alert("You can't enter value 0 ");
        return;
      } else {
        let hour = parseInt(hours) - parseInt(incrementminVal);
        if (hour < 24 && hour > 0) {
          inputValues.hours = "" + hour;
          localkundalidata.birthTime.hour = hour + "";
          localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
          setUserDetails({
            ...userdetails1,
            dob: { ...userdetails1.dob, year: e.target.value },
          });
        } else {
          alert("Hours can be 0-23 only");
          return;
        }
      }
    } else {
      let newincrement = parseInt(incrementminVal);
      if (newincrement === 0) {
        alert("You can't enter value 0 ");
        return;
      } else {
        minutes = parseInt(minutes) - parseInt(incrementminVal);

        if (minutes < 59 && minutes > 0) {
          inputValues.minutes = "" + minutes;
          localkundalidata.birthTime.minute = minutes + "";
          localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
          setInputValue({ ...inputValues, minutes: minutes + "" });
        } else {
          alert("Minute value should be 1-59");
          return;
        }
      }
    }

    setIsLoading(true);

    let dateObj = new Date();
    let output = "";
    let month = dateObj.getMonth() + 1;
    let day = String(dateObj.getDate()).padStart(2, "0");
    let year = dateObj.getFullYear();
    let currentime =
      (dateObj.getHours() < 10 ? "0" : "") +
      dateObj.getHours() +
      ":" +
      (dateObj.getMinutes() < 10 ? "0" : "") +
      dateObj.getMinutes();
    setCurrentTime(currentime);
    let currentdate;
    if (month < 10) {
      //   output = day + '/' + '0' + month + '/' + year;
      currentdate = day + "/" + "0" + month + "/" + year;
      setCurrentDate(currentdate);
    } else {
      //   output = day + '/' + month + '/' + year;
      currentdate = day + "/" + month + "/" + year;
      setCurrentDate(currentdate);
    }
    // console.log("timezonetimezonetimezone in increment decrement==", timezone);
    let dataObj = {
      langitutde: inputValues.longitude,
      gender: gender,
      kundalitype: kundalitype,
      birthDate: {
        day: inputValues.day,
        month: inputValues.month,
        year: inputValues.year,
      },
      timezone: inputValues.pob.timezone,
      language: inputValues.language,
      // "product": product,
      product: inputValues.product,
      latitude: inputValues.latitude,
      name: inputValues.uname,
      dst: dst,
      generate: generate,
      pob: inputValues.pob,
      // "birthTime": birthTime,
      birthTime: {
        hour: inputValues.hours,
        minute: inputValues.minutes,
      },
      rotatekundali: inputValues.predfor,
      currentDate: currentdate,
      currentTime: currentime,
      showpdf: showpdf,
      showgochar: showgochar,
      ageRange: ageRange,
      acharyaid: acharyaid,
      finalyear: finalyear,
    };
    const data = await dispatch(getGocharKundaliInc(dataObj, userdetails1));
    if (getGocharKundaliInc) {
      clearQuestionAnswers();
      setIsLoading(false);
    }
    //updatetob(incrementminVal);
  };

  const plan_status = useSelector((e) => e.plan);
  // console.log(plan_status);
  const splans = plan_status.subs_plan;
  const rplans = plan_status.reg_plan;
  const planStatus = plan_status.plan_status;

  const getQuestionanswer = async () => {
    setIsLoading(true);
    setQuIsLoading(true);
    let dateObj = new Date();
    let output = "";
    let month = dateObj.getMonth() + 1;
    let day = String(dateObj.getDate()).padStart(2, "0");
    let year = dateObj.getFullYear();
    let currentime =
      (dateObj.getHours() < 10 ? "0" : "") +
      dateObj.getHours() +
      ":" +
      (dateObj.getMinutes() < 10 ? "0" : "") +
      dateObj.getMinutes();
    setCurrentTime(currentime);
    let currentdate;
    if (month < 10) {
      //   output = day + '/' + '0' + month + '/' + year;
      currentdate = day + "/" + "0" + month + "/" + year;
      setCurrentDate(currentdate);
    } else {
      //   output = day + '/' + month + '/' + year;
      currentdate = day + "/" + month + "/" + year;
      setCurrentDate(currentdate);
    }

    let dataObj = {
      langitutde: inputValues.longitude,
      gender: gender,
      kundalitype: kundalitype,
      birthDate: {
        day: inputValues.day,
        month: inputValues.month,
        year: inputValues.year,
      },
      timezone: inputValues.pob.timezone,
      language: inputValues.language,
      product: inputValues.product,
      latitude: inputValues.latitude,
      name: inputValues.uname,
      dst: dst,
      generate: generate,
      pob: inputValues.pob,
      birthTime: {
        hour: inputValues.hours,
        minute: inputValues.minutes,
      },
      rotatekundali: inputValues.predfor,
      currentDate: currentdate,
      currentTime: currentime,
      showpdf: showpdf,
      showgochar: showgochar,
      ageRange: ageRange,
      acharyaid: acharyaid,
      btntype: "viewkundali",
      finalyear: finalyear,
      message: "",
      generateKundaliProduct: "",
    };
    localStorage.setItem("bodydata", JSON.stringify(dataObj));
    localStorage.setItem("kundalidata", JSON.stringify(dataObj));
  };

  useEffect(() => {
    getData();
    getLanguages();
  }, [products.length, allData.length, languageList.length]);

  const messagesEndRef = useRef(null);
  // console.log(inputValues.language, "inputValues.language");

  const predictionTab = async () => {
    let user = localStorage.getItem("user");
    const userDetail = JSON.parse(user);
    const acharyaid = userDetail["id"];
    const acharyaname = userDetail["name"];
    let dateObj = new Date();
    let output = "";
    let month = dateObj.getMonth() + 1;
    let day = String(dateObj.getDate()).padStart(2, "0");
    let year = dateObj.getFullYear();
    let currentime =
      (dateObj.getHours() < 10 ? "0" : "") +
      dateObj.getHours() +
      ":" +
      (dateObj.getMinutes() < 10 ? "0" : "") +
      dateObj.getMinutes();
    setCurrentTime(currentime);

    // console.log(currentime, "currentime");
    let currentdate;
    if (month < 10) {
      //   output = day + '/' + '0' + month + '/' + year;
      currentdate = day + "/" + "0" + month + "/" + year;
      setCurrentDate(currentdate);
    } else {
      //   output = day + '/' + month + '/' + year;
      currentdate = day + "/" + month + "/" + year;
      setCurrentDate(currentdate);
    }
    let dataObj = {
      langitutde: inputValues.longitude,
      gender: gender,
      kundalitype: kundalitype,
      birthDate: {
        day: inputValues.day,
        month: inputValues.month,
        year: inputValues.year,
      },
      timezone: inputValues.pob.timezone,
      language: inputValues.language,
      product: "150",
      latitude: inputValues.latitude,
      name: inputValues.uname,
      dst: dst,
      generate: generate,
      pob: inputValues.pob,
      birthTime: {
        hour: inputValues.hours,
        minute: inputValues.minutes,
      },
      rotatekundali: inputValues.predfor,
      currentDate: currentdate,
      currentTime: currentime,
      showpdf: showpdf,
      showgochar: showgochar,
      ageRange: ageRange,
      acharyaid: acharyaid,
      branding: branding,
      btntype: "prediction",
      source: "GDV Panel",
    };
    setispedLoading(true);
    setMessage();

    const res = await axios.post(
      `${BAS_URL_APPAPI}acharya/downloadkundali`,
      dataObj
    );

    // console.log(res, "download");
    let body = {
      language1: res.data.data.language,
      pdflink: res.data.data.pdflink,
      productName: res.data.data.productName,
      vcnno: res.data.data.productdetails.vcnno,
      name: res.data.data.productdetails.name,
      gender1: res.data.data.productdetails.gender,
      dob: res.data.data.productdetails.dob,
      pob: res.data.data.productdetails.pob,
      tob: res.data.data.productdetails.tob,
      mobile: "",
      acharyaid: acharyaid,
      kundli_for: rotatekundali,
      acharyaname: acharyaname,
    };
    let pdfcheckh;
    setTimeout(() => {
      pdfcheckh = axios.get(
        `${VASHISHT_API_URL}horoscop/pdfcheck/${body.vcnno}`
      );
      if ((pdfcheckh.data = true)) {
        let msg = body.pdflink ? body.pdflink : "";
        setMessage(msg);
        setispedLoading(false);
        localkundalidata.message = msg;
        localkundalidata.generateKundaliProduct = generateKundaliProduct;
        localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
      } else {
        setispedLoading(true);
      }
    }, 3000);
    // console.log("pdfcheckhpdfcheckhpdfcheckhpdfcheckh outside==", pdfcheckh);

    let msg = body.pdflink ? body.pdflink : "";
    setMessage(msg);
    setispedLoading(false);
    localkundalidata.message = msg;
    localkundalidata.generateKundaliProduct = generateKundaliProduct;
    localStorage.setItem("kundalidata", JSON.stringify(localkundalidata));
    setIsLoading(false);
  };

  const [validation, setValidation] = useState({
    uname: "",
    place: "",
    day: "",
    month: "",
    year: "",
    hours: "",
    minutes: "",
    product: "",
    language: "",
    predfor: "",
    female: "",
    male: "",
    dst: "",
    timezone: "",
    gdvashist: "",
    Self: "",
    latitude: "",
    longitude: "",
  });

  const [extraMonths, setExtraMonths] = useState(0);
  const [extraDays, setExtraDays] = useState(0);
  const [yearsBetween, setYearsBetween] = useState();

  function VarshRange(year, month, day) {
    const currentDate = moment();
    let pastDate = moment(`${year}-${month}-${day}`);
    const yearsBetween = currentDate.diff(pastDate, "years");
    const adjustedPastDate = pastDate.add(yearsBetween, "years");
    const monthsBetween = currentDate.diff(adjustedPastDate, "months");
    const adjustedPastDateWithMonths = adjustedPastDate.add(
      monthsBetween,
      "months"
    );
    const daysBetween = currentDate.diff(adjustedPastDateWithMonths, "days");
    // setExtraMonths(monthsBetween);
    // setExtraDays(daysBetween);
    const yearsBetweenFinal =
      daysBetween >= 0 || monthsBetween > 0 ? yearsBetween + 1 : yearsBetween;
    return yearsBetweenFinal;
  }

  useEffect(() => {
    // Current date
    if (
      localkundalidata.birthDate.year &&
      localkundalidata.birthDate.month &&
      localkundalidata.birthDate.day
    ) {
      const currentDate = moment();
      let pastDate = moment(
        `${localkundalidata.birthDate.year}-${localkundalidata.birthDate.month}-${localkundalidata.birthDate.day}`
      );
      const yearsBetween = currentDate.diff(pastDate, "years");
      const adjustedPastDate = pastDate.add(yearsBetween, "years");
      const monthsBetween = currentDate.diff(adjustedPastDate, "months");
      const adjustedPastDateWithMonths = adjustedPastDate.add(
        monthsBetween,
        "months"
      );
      const daysBetween = currentDate.diff(adjustedPastDateWithMonths, "days");
      // setExtraMonths(monthsBetween);
      // setExtraDays(daysBetween);
      const yearsBetweenFinal =
        daysBetween >= 0 || monthsBetween > 0 ? yearsBetween + 1 : yearsBetween;
      setYearsBetween(yearsBetweenFinal);
    } else {
      const currentDate = moment();
      let pastDate = moment(
        `${inputValues?.year}-${inputValues?.month}-${inputValues?.day}`
      );
      const yearsBetween = currentDate.diff(pastDate, "years");
      const adjustedPastDate = pastDate.add(yearsBetween, "years");
      const monthsBetween = currentDate.diff(adjustedPastDate, "months");
      const adjustedPastDateWithMonths = adjustedPastDate.add(
        monthsBetween,
        "months"
      );
      const daysBetween = currentDate.diff(adjustedPastDateWithMonths, "days");
      // setExtraMonths(monthsBetween);
      // setExtraDays(daysBetween);
      const yearsBetweenFinal =
        daysBetween >= 0 || monthsBetween > 0 ? yearsBetween + 1 : yearsBetween;
      setYearsBetween(yearsBetweenFinal);
    }
  }, [localkundalidata.birthDate]);

  let currentyear = moment().year();
  let finalyear = yearsBetween;

  // console.log(finalyear, "currentyear");

  if (finalyear === 0) {
    finalyear = 1;
  }

  console.log(
    extraMonths,
    extraDays,
    finalyear,
    localkundalidata.birthDate.year,
    yearsBetween,
    "extraDays"
  );

  //handle submit updates
  function handleChange(event) {
    const { name, value } = event.target;
    if (value) {
      validation[name] = "";
    }
    setInputValue({ ...inputValues, [name]: value });
  }

  //handle submit updates
  function handleChangeName(event) {
    const { value } = event.target;

    const regex = /^[A-Za-z\s]+$/;

    // Validate the input
    if (value != " ") {
      if (regex.test(value)) {
        setInputValue({ ...inputValues, uname: value });
      } else {
        setInputValue({ ...inputValues, uname: "" });
      }
    } else {
      setInputValue({ ...inputValues, uname: "" });
    }
  }

  const checkValidation = (e) => {
    let errors = {
      uname: "",
      place: "",
      day: "",
      month: "",
      year: "",
      hours: "",
      minutes: "",
      product: "",
      language: "",
      predfor: "",
      female: "",
      male: "",
      dst: "",
      timezone: "",
      gdvashist: "",
      Self: "",
      latitude: "",
      longitude: "",
    };
    let check = true;
    if (!inputValues.uname.trim()) {
      errors.uname = "Required *";
      check = false;
    } else {
      errors.uname = "";
    }
    if (!inputValues.place.trim()) {
      errors.place = "Required *";
      check = false;
    } else {
      errors.place = "";
    }
    if (!inputValues.latitude.trim()) {
      errors.latitude = "Required *";
      check = false;
    } else {
      errors.latitude = "";
    }
    if (!inputValues.longitude.trim()) {
      errors.longitude = "Required *";
      check = false;
    } else {
      errors.longitude = "";
    }
    if (!inputValues.day.trim()) {
      errors.day = "Required *";
      check = false;
    } else {
      errors.day = "";
    }
    if (!inputValues.month.trim()) {
      errors.month = "Required *";
      check = false;
    } else {
      errors.month = "";
    }

    if (!inputValues.year.trim()) {
      errors.year = "Required *";
      check = false;
    } else {
      errors.year = "";
    }
    if (!inputValues.hours.trim()) {
      errors.hours = "Required *";
      check = false;
    } else {
      errors.hours = "";
    }
    if (!inputValues.minutes.trim()) {
      errors.minutes = "Required *";
      check = false;
    } else {
      errors.minutes = "";
    }
    if (typeof inputValues.product == string && !inputValues.product.trim()) {
      errors.product = "Required *";
      check = false;
    } else if (
      typeof inputValues.product == number &&
      !(inputValues.product > 0)
    ) {
      errors.product = "Required *";
      check = false;
    } else {
      errors.product = "";
    }
    if (typeof inputValues.language == string && !inputValues.language.trim()) {
      errors.language = "Required *";
      check = false;
    } else if (
      typeof inputValues.language == number &&
      !(inputValues.language > 0)
    ) {
      errors.language = "Required *";
      check = false;
    } else {
      errors.language = "";
    }
    if (typeof inputValues.predfor == string && !inputValues.predfor.trim()) {
      errors.predfor = "Required *";
      check = false;
    } else if (
      typeof inputValues.predfor == number &&
      !!(inputValues.predfor > 0)
    ) {
      errors.predfor = "Required *";
      check = false;
    } else {
      errors.predfor = "";
    }
    let dat = `${inputValues.month}/${inputValues.day}/${inputValues.year}`;
    let date = new Date(dat);
    // console.log(
    //   "VALID DATE",
    //   date instanceof Date && isFinite(date.getTime()),
    //   date,
    //   dat
    // );
    if (!(date instanceof Date && isFinite(date.getTime()))) {
      errors.day = "Invalid*";
      check = false;
    }

    if (!(inputValues.year >= 1924 && inputValues.year <= 2224)) {
      errors.year = "Invalid*";
      check = false;
      // console.log(inputValues.year, check,"inputValues.year")
    }

    if (!(inputValues.month >= 1 && inputValues.month <= 12)) {
      errors.month = "Invalid*";
      check = false;
      // console.log(inputValues.year, check,"inputValues.year")
    }

    if (!(inputValues.day >= 1 && inputValues.day <= 31)) {
      errors.day = "Invalid*";
      check = false;
      // console.log(inputValues.year, check,"inputValues.year")
    }

    // leap year check
    if (moment([inputValues?.year]).isLeapYear() && inputValues?.month == 2) {
      if (!(inputValues?.day >= 1 && inputValues?.day <= 29)) {
        errors.day = "Invalid*";
        check = false;
      }
    } else if (
      !moment([inputValues?.year]).isLeapYear() &&
      inputValues?.month == 2
    ) {
      if (!(inputValues?.day >= 1 && inputValues?.day <= 28)) {
        errors.day = "Invalid*";
        check = false;
      }
    } else {
      if (!(inputValues?.day >= 1 && inputValues?.day <= 31)) {
        errors.day = "Invalid*";
        check = false;
      }
    }

    // console.log("CHECK", check, errors);
    setValidation(errors);
    if (check) {
      Submit(e);
    }
  };

  // const checkGenerated = (e) => {
  //   let notifyMessage =
  //     "Please Generate Report first to view the view Questions";
  //   let optiondata = {};
  //   optiondata = {
  //     place: "tc",
  //     message: notifyMessage,
  //     type: "danger",
  //     icon: "tim-icons icon-bell-55",
  //     autoDismiss: 7,
  //   };

  //   notificationAlertRef.current.notificationAlert(optiondata);
  // };

  const checkSubscription = () => {
    let notifyMessage =
      "Your plan is expired, please renew to view kundali and questions";
    let optiondata = {};
    optiondata = {
      place: "tc",
      message: notifyMessage,
      type: "danger",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };

    notificationAlertRef.current.notificationAlert(optiondata);
  };

  const FetchError = () => {
    let notifyMessage = "Unable to Fetch Data";
    let optiondata = {};
    optiondata = {
      place: "tc",
      message: notifyMessage,
      type: "danger",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7,
    };

    notificationAlertRef.current.notificationAlert(optiondata);
  };

  const checkValidationque = (e) => {
    let errors = validation;
    let check = true;
    // console.log(inputValues);
    if (!inputValues.uname.trim()) {
      errors.uname = "Required *";
      check = false;
    } else {
      errors.uname = "";
    }
    if (!inputValues.place.trim()) {
      errors.place = "Required *";
      check = false;
    } else {
      errors.place = "";
    }
    if (!inputValues.latitude.trim()) {
      errors.latitude = "Required *";
      check = false;
    } else {
      errors.latitude = "";
    }
    if (!inputValues.longitude.trim()) {
      errors.longitude = "Required *";
      check = false;
    } else {
      errors.longitude = "";
    }
    if (!inputValues.day.trim()) {
      errors.day = "Required *";
      check = false;
    } else {
      errors.day = "";
    }
    if (!inputValues.month.trim()) {
      errors.month = "Required *";
      check = false;
    } else {
      errors.month = "";
    }

    if (!inputValues.year.trim()) {
      errors.year = "Required *";
      check = false;
    } else {
      errors.year = "";
    }
    if (!inputValues.hours.trim()) {
      errors.day = "Required *";
      check = false;
    } else {
      errors.day = "";
    }
    if (!inputValues.minutes.trim()) {
      errors.month = "Required *";
      check = false;
    } else {
      errors.month = "";
    }
    if (!(inputValues.product + "").trim()) {
      errors.product = "Required *";
      check = false;
    } else {
      errors.product = "";
    }
    if (!(inputValues.language + "").trim()) {
      errors.language = "Required *";
      check = false;
    } else {
      errors.language = "";
    }
    if (!(inputValues.predfor + "").trim()) {
      errors.predfor = "Required *";
      check = false;
    } else {
      errors.predfor = "";
    }

    if (check) {
      setValidation(errors);
      getQuestionanswer(e);

      if (questionans) {
        setPred();
        setIsLoading(false);
        if (alldatatrue) {
          history.push("/acharya/questions");
        } else {
          let notifyMessage = "Please generate report first!!";
          let optiondata = {};
          optiondata = {
            place: "tc",
            message: notifyMessage,
            type: "danger",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 7,
          };
          notificationAlertRef.current.notificationAlert(optiondata);
        }
      } else {
        let notifyMessage =
          "Facing some problem in Questions. Please contact support!!";
        let optiondata = {};
        optiondata = {
          place: "tc",
          message: notifyMessage,
          type: "primary",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 7,
        };
        notificationAlertRef.current.notificationAlert(optiondata);
      }
    } else {
      let notifyMessage = "Please enter birth details!!";
      let optiondata = {};
      optiondata = {
        place: "tc",
        message: notifyMessage,
        type: "primary",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 7,
      };
      notificationAlertRef.current.notificationAlert(optiondata);
    }
  };

  const checkValidationpred = (generateKundali) => {
    let errors = validation;
    let check = true;
    if (!inputValues.uname.trim()) {
      errors.uname = "Required *";
      check = false;
    } else {
      errors.uname = "";
    }
    if (!inputValues.place.trim()) {
      errors.place = "Required *";
      check = false;
    } else {
      errors.place = "";
    }
    if (!inputValues.latitude.trim()) {
      errors.latitude = "Required *";
      check = false;
    } else {
      errors.latitude = "";
    }
    if (!inputValues.longitude.trim()) {
      errors.longitude = "Required *";
      check = false;
    } else {
      errors.longitude = "";
    }
    if (!inputValues.day.trim()) {
      errors.day = "Required *";
      check = false;
    } else {
      errors.day = "";
    }
    if (!inputValues.month.trim()) {
      errors.month = "Required *";
      check = false;
    } else {
      errors.month = "";
    }
    if (!inputValues.year.trim()) {
      errors.year = "Required *";
      check = false;
    } else {
      errors.year = "";
    }
    if (!inputValues.hours.trim()) {
      errors.day = "Required *";
      check = false;
    } else {
      errors.day = "";
    }
    if (!inputValues.minutes.trim()) {
      errors.month = "Required *";
      check = false;
    } else {
      errors.month = "";
    }
    if (typeof inputValues.product == string && !inputValues.product.trim()) {
      errors.product = "Required *";
      check = false;
    } else if (
      typeof inputValues.product == number &&
      !(inputValues.product > 0)
    ) {
      errors.product = "Required *";
      check = false;
    } else {
      errors.product = "";
    }
    if (typeof inputValues.language == string && !inputValues.language.trim()) {
      errors.language = "Required *";
      check = false;
    } else if (
      typeof inputValues.language == number &&
      !(inputValues.language > 0)
    ) {
      errors.language = "Required *";
      check = false;
    } else {
      errors.language = "";
    }
    if (typeof inputValues.predfor == string && !inputValues.predfor.trim()) {
      errors.predfor = "Required *";
      check = false;
    } else if (
      typeof inputValues.predfor == number &&
      !!(inputValues.predfor > 0)
    ) {
      errors.predfor = "Required *";
      check = false;
    } else {
      errors.predfor = "";
    }

    if (check) {
      setValidation(errors);
      predictionTab(generateKundali);
    }
  };
  // const checkPlan = useSelector((e) => e.plan);
  // console.log(checkPlan, "checkPlan");

  const checkValidationsave = (e) => {
    let errors = {
      uname: "",
      place: "",
      day: "",
      month: "",
      year: "",
      hours: "",
      minutes: "",
      product: "",
      language: "",
      predfor: "",
      female: "",
      male: "",
      dst: "",
      timezone: "",
      gdvashist: "",
      Self: "",
      latitude: "",
      longitude: "",
    };
    let check = true;
    if (!inputValues.uname.trim()) {
      errors.uname = "Required *";
      check = false;
    } else {
      errors.uname = "";
    }
    if (!inputValues.place.trim()) {
      errors.place = "Required *";
      check = false;
    } else {
      errors.place = "";
    }
    if (!inputValues.latitude.trim()) {
      errors.latitude = "Required *";
      check = false;
    } else {
      errors.latitude = "";
    }
    if (!inputValues.longitude.trim()) {
      errors.longitude = "Required *";
      check = false;
    } else {
      errors.longitude = "";
    }
    if (!inputValues.day.trim()) {
      errors.day = "Required *";
      check = false;
    } else {
      errors.day = "";
    }
    if (!inputValues.month.trim()) {
      errors.month = "Required *";
      check = false;
    } else {
      errors.month = "";
    }

    if (!inputValues.year.trim()) {
      errors.year = "Required *";
      check = false;
    } else {
      errors.year = "";
    }
    if (!inputValues.hours.trim()) {
      errors.hours = "Required *";
      check = false;
    } else {
      errors.hours = "";
    }
    if (!inputValues.minutes.trim()) {
      errors.minutes = "Required *";
      check = false;
    } else {
      errors.minutes = "";
    }
    if (typeof inputValues.product == string && !inputValues.product.trim()) {
      errors.product = "Required *";
      check = false;
    } else if (
      typeof inputValues.product == number &&
      !(inputValues.product > 0)
    ) {
      errors.product = "Required *";
      check = false;
    } else {
      errors.product = "";
    }
    if (typeof inputValues.language == string && !inputValues.language.trim()) {
      errors.language = "Required *";
      check = false;
    } else if (
      typeof inputValues.language == number &&
      !(inputValues.language > 0)
    ) {
      errors.language = "Required *";
      check = false;
    } else {
      errors.language = "";
    }
    if (typeof inputValues.predfor == string && !inputValues.predfor.trim()) {
      errors.predfor = "Required *";
      check = false;
    } else if (
      typeof inputValues.predfor == number &&
      !!(inputValues.predfor > 0)
    ) {
      errors.predfor = "Required *";
      check = false;
    } else {
      errors.predfor = "";
    }
    let dat = `${inputValues.month}/${inputValues.day}/${inputValues.year}`;
    let date = new Date(dat);
    // console.log(
    //   "VALID DATE",
    //   date instanceof Date && isFinite(date.getTime()),
    //   date,
    //   dat
    // );
    if (!(date instanceof Date && isFinite(date.getTime()))) {
      errors.day = "Invalid*";
      check = false;
    }

    if (!(inputValues.year >= 1924 && inputValues.year <= 2224)) {
      errors.year = "Invalid*";
      check = false;
      // console.log(inputValues.year, check,"inputValues.year")
    }

    if (!(inputValues.month >= 1 && inputValues.month <= 12)) {
      errors.month = "Invalid*";
      check = false;
      // console.log(inputValues.year, check,"inputValues.year")
    }

    if (!(inputValues.day >= 1 && inputValues.day <= 31)) {
      errors.day = "Invalid*";
      check = false;
      // console.log(inputValues.year, check,"inputValues.year")
    }

    // leap year check
    if (moment([inputValues?.year]).isLeapYear() && inputValues?.month == 2) {
      if (!(inputValues?.day >= 1 && inputValues?.day <= 29)) {
        errors.day = "Invalid*";
        check = false;
      }
    } else if (
      !moment([inputValues?.year]).isLeapYear() &&
      inputValues?.month == 2
    ) {
      if (!(inputValues?.day >= 1 && inputValues?.day <= 28)) {
        errors.day = "Invalid*";
        check = false;
      }
    } else {
      if (!(inputValues?.day >= 1 && inputValues?.day <= 31)) {
        errors.day = "Invalid*";
        check = false;
      }
    }
    // console.log("CHECK", check, errors);
    setValidation(errors);
    if (check) {
      setValidation(errors);
      // Submit(e);
      openModal();
    }
  };

  const clearAllData = (e) => {
    setGender("male");
    allData.horo = {};
    allData.pred = {};
    allData.maha = {};
    kundaliid = "";
    message = "";
    let dataObj = {
      langitutde: "",
      gender: "",
      kundalitype: "",
      birthDate: {
        day: "",
        month: "",
        year: "",
      },
      timezone: "",
      language: "1",
      product: "150",
      latitude: "",
      name: "",
      dst: "",
      generate: "",
      pob: {
        placeName: "",
        StateName: "",
        countryName: "",
        latitude: "",
        longitude: "",
        gmtoffset: "",
        dstoffset: "",
        timezone: "",
      },
      birthTime: {
        hour: "",
        minute: "",
      },
      rotatekundali: "1",
      currentDate: "",
      currentTime: "",
      showpdf: true,
      showgochar: true,
      ageRange: "",
      branding: "",
      acharyaid: "",
      btntype: "",
      message: "",
      generateKundaliProduct: "",
    };
    localStorage.setItem("kundalidata", JSON.stringify(dataObj));
    setGenerateKundaliProduct(inputValues.product);
    setMessage("");
    setInputValue({
      uname: "",
      place: "",
      day: "",
      month: "",
      year: "",
      hours: "",
      minutes: "",
      product: "150",
      language: "1",
      predfor: "1",
      female: "",
      male: "",
      dst: "",
      gdvashist: "",
      Self: "",
      latitude: "",
      longitude: "",
      pob: "",
    });
    localStorage.removeItem("bodydata");
    localStorage.removeItem("goodtime");
    localStorage.removeItem("occupation");
    localStorage.removeItem("health");
    localStorage.removeItem("marriage");
    localStorage.removeItem("generaldata");
    localStorage.removeItem("goodtime");
    localStorage.removeItem("vehicle");
    localStorage.removeItem("datefinder");
    localStorage.removeItem("parent");
    localStorage.removeItem("badtime");
    localStorage.removeItem("foreign");
    localStorage.removeItem("answerOpened");
    setAlldatatrue(false);
    allData.horo = {};
    allData.pred = {};
    allData.maha = {};
    dispatch({
      type: HOROSCOPE_POST,
      payload: {},
    });
    setUserDetails({
      ...userdetails1,
      dob: {
        day: inputValues.day,
        month: inputValues.month,
        year: inputValues.year,
      },
    });
  };

  const [generateKundaliProduct, setGenerateKundaliProduct] = useState(
    localkundalidata.generateKundaliProduct
  );
  let [message, setMessage] = useState(localkundalidata.message);
  // console.log("ALL DATA ====--------======= ", generateKundaliProduct);

  const location = useLocation();

  // console.log("paramparamparamparam", location.state);
  // console.log("Params Id===", location.state);
  var kundaliid = location.state
    ? location.state.view_id
      ? location.state.view_id
      : ""
    : "";
  // console.log("kundaliid===", kundaliid);
  const getKundalibyId = async () => {
    if (kundaliid) {
      localkundalidata.message = "";
      localkundalidata.generateKundaliProduct = inputValues.product;
      setMessage("");
      let result = await axios.post(
        `${BAS_URL_APPAPI}acharya/viewkundali/${kundaliid}`
      );
      // console.log("Kundali data=====", result, result.data.data.length);
      if (result) {
        let view_kundali_data = result.data.data[0];
        // console.log("birthDatebirthDatebirthDate 11", view_kundali_data);
        let dob = view_kundali_data.dob.split("/");
        let tob = view_kundali_data.tob.split(":");
        // console.log("birthDatebirthDatebirthDate",dayn);
        // console.log("birthDatebirthDatebirthDate", view_kundali_data);
        setInputValue({
          uname: view_kundali_data.name + "",
          place: view_kundali_data.pob + "",
          day: dob[0] + "",
          month: dob[1] + "",
          year: dob[2] + "",
          hours: tob[0] + "",
          minutes: tob[1] + "",
          product: parseInt(view_kundali_data.productId),
          language: parseInt(view_kundali_data.language),
          predfor: parseInt(view_kundali_data.kundaliFor),
          female: view_kundali_data.gender + "",
          male: view_kundali_data.gender + "",
          dst: view_kundali_data.timezone,
          gdvashist: "",
          Self: "",
          latitude: view_kundali_data.latitude + "",
          longitude: view_kundali_data.longitude + "",
          pob: JSON.parse(view_kundali_data.pobstring),
        });
        let dateObj = new Date();
        let output = "";
        let month = dateObj.getMonth() + 1;
        let day = String(dateObj.getDate()).padStart(2, "0");
        let year = dateObj.getFullYear();
        let currentime =
          (dateObj.getHours() < 10 ? "0" : "") +
          dateObj.getHours() +
          ":" +
          (dateObj.getMinutes() < 10 ? "0" : "") +
          dateObj.getMinutes();
        setCurrentTime(currentime);
        let currentdate;
        if (month < 10) {
          //   output = day + '/' + '0' + month + '/' + year;
          currentdate = day + "/" + "0" + month + "/" + year;
          setCurrentDate(currentdate);
        } else {
          //   output = day + '/' + month + '/' + year;
          currentdate = day + "/" + month + "/" + year;
          setCurrentDate(currentdate);
        }
        // console.log("timezonetimezonetimezone === in dashboard==", timezone);
        userdetails1.dob.day = dob[0];
        userdetails1.dob.month = dob[1];
        userdetails1.dob.year = dob[2];
        const results = await VarshRange(dob[2], dob[1], dob[0]);
        // console.log(`${dob[2]}-${dob[1]}-${day}`, results, "resultresult");

        let dataObj = {
          langitutde: view_kundali_data.longitude + "",
          gender: view_kundali_data.gender + "",
          kundalitype: kundalitype,
          birthDate: {
            day: dob[0],
            month: dob[1],
            year: dob[2],
          },
          timezone: view_kundali_data.timezone,
          language: view_kundali_data.language,
          product: view_kundali_data.productId,
          latitude: view_kundali_data.latitude + "",
          name: view_kundali_data.name + "",
          dst: view_kundali_data.timezone,
          generate: generate,
          pob: JSON.parse(view_kundali_data.pobstring),
          birthTime: {
            hour: tob[0],
            minute: tob[1],
          },
          rotatekundali: view_kundali_data.kundaliFor,
          showpdf: showpdf,
          showgochar: showgochar,
          ageRange: ageRange,
          acharyaid: acharyaid,
          btntype: "viewkundali",
          finalyear: results,
          message: "",
          generateKundaliProduct: "",
          currentDate: currentdate,
          currentTime: currentime,
        };

        userdetails1.name = view_kundali_data.name + "";
        localStorage.setItem("kundalidata", JSON.stringify(dataObj));
        // console.log("viewKundali === ");

        finalyear = results;
        // console.log(finalyear,"finalyearfinalyear")
        viewKundali(dataObj);
      }
    }
  };

  // console.log(object)

  useEffect(() => {
    if (localkundalidata.name === "") {
      getKundalibyId();
    }
  }, [kundaliid]);

  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  const getAllNotification = async () => {
    try {
      const allnotification = await axios.get(
        `${BAS_URL_APPAPI}acharya/notificationByStatus`
      );
      // console.log("All Notification==============", allnotification.data.data);

      setNotification(allnotification.data.data);
      if (allnotification.data.data.length) {
        setModalSearch(true);
      }

      setLoader(false);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    // getAllNotification();
    dispatch(checkplan());
    if (localkundalidata.gender) {
      setGender(localkundalidata.gender);
    }
    if (localkundalidata.language) {
      setLanguage(localkundalidata.language);
      dispatch(selectedlanguage(localkundalidata.language));
    }
  }, [localkundalidata.gender, localkundalidata.language]);

  const height1 = document.getElementById("height-left");
  const heightLeft = height1?.offsetHeight;
  // console.log(heightLeft,"heightLeft")

  const height2 = document.getElementById("height-chart");
  const heightChart = height2?.offsetHeight;
  // console.log(heightLeft,"heightLeft")///////

  const addZeroes = (e, value) => {
    if (inputValues[value] >= 1 && inputValues[value] <= 9) {
      setInputValue({
        ...inputValues,
        [value]: inputValues[value]?.padStart(2, 0),
      });
    } else {
      setInputValue({
        ...inputValues,
        [value]: inputValues[value],
      });
    }
  };

  // console.log(inputValues, "inputValues");
  // console.log(!moment(["1997"]).isLeapYear(),"jmnnnn")

  return (
    <>
      <div>{isLoading ? <Threedotscomp /> : ""} </div>
      <div className="content container-fluid" ref={messagesEndRef}>
        <NotificationAlert ref={notificationAlertRef} />
        <Container fluid className="px-0">
          <Row className="mb-0 mt-1 px-2" noGutters>
            <Col lg={12} xs={12} md={12}>
              <Card className="mb-3">
                <CardBody className="mt-0 shadow-sm rounded-sm">
                  <ButtonGroup
                    className="btn-group-toggle d-flex ml-[5px]"
                    data-toggle="buttons"
                    style={{ gap: 3 }}
                  >
                    <Row>
                      <Col md="12" style={{ display: "flex", gap: 2 }}>
                        <button
                          className="btn"
                          style={{
                            height: "40px",
                            alignContent: "center",
                            padding: "5px 16px",
                            fontSize: "12px",
                            color: "white",
                            backgroundImage:
                              "linear-gradient(to bottom left, #344675, #263148, #344675)",
                            border: "1px solid #212529",
                            borderRadius: "4px",
                          }}
                        >
                          Horoscope
                        </button>

                        {planStatus === 0 ? (
                          <>
                            <button
                              className="btn btn-simple"
                              style={{
                                height: "40px",
                                alignContent: "center",
                                padding: "5px 16px",
                                fontSize: "12px",
                                color: "#263148",
                                background: "transparent",
                                border: "1px solid #212529",
                                borderRadius: "4px",
                              }}
                              tabIndex="18"
                              onClick={(e) => {
                                checkSubscription(e);
                              }}
                              disabled
                            >
                              Questions
                            </button>
                          </>
                        ) : planStatus === 1 ? (
                          <>
                            <button
                              className="btn btn-simple"
                              style={{
                                height: "40px",
                                alignContent: "center",
                                padding: "5px 16px",
                                fontSize: "12px",
                                color: "#263148",
                                background: "transparent",
                                border: "1px solid #212529",
                                borderRadius: "4px",
                              }}
                              tabIndex="18"
                              onClick={(e) => {
                                checkValidationque(e);
                              }}
                            >
                              Questions
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              className="btn btn-simple"
                              style={{
                                height: "40px",
                                alignContent: "center",
                                padding: "5px 16px",
                                fontSize: "12px",
                                color: "#1d8cf8",
                                background: "transparent",
                                border: "1px solid #212529",
                                borderRadius: "4px",
                              }}
                              tabIndex="18"
                              onClick={FetchError}
                            >
                              Questions
                            </button>
                          </>
                        )}
                        {splans && (splans == 4 && (
                          
                          planStatus === 0 ? <button
                            className="btn btn-simple"
                            style={{
                              height: "40px",
                              alignContent: "center",
                              padding: "5px 16px",
                              fontSize: "12px",
                              color: "#263148",
                              background: "transparent",
                              border: "1px solid #212529",
                              borderRadius: "4px",
                            }}
                            tabindex="18"
                            disabled
                            // to="/acharya/match-making"
                          >
                            Match Making
                          </button> : planStatus === 1 ? <Link
                            className="btn btn-simple"
                            style={{
                              height: "40px",
                              alignContent: "center",
                              padding: "5px 16px",
                              fontSize: "12px",
                              color: "#263148",
                              background: "transparent",
                              border: "1px solid #212529",
                              borderRadius: "4px",
                            }}
                            tabindex="18"
                            to="/acharya/match-making"
                          >
                            Match Making
                          </Link> : <button
                            className="btn btn-simple"
                            style={{
                              height: "40px",
                              alignContent: "center",
                              padding: "5px 16px",
                              fontSize: "12px",
                              color: "#263148",
                              background: "transparent",
                              border: "1px solid #212529",
                              borderRadius: "4px",
                            }}
                            tabindex="18"
                            disabled
                            
                          >
                            Match Making
                          </button> 
                        ))}
                      </Col>
                    </Row>
                  </ButtonGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Row noGutters>
                <Col lg="5" xl="5" md="12" className="px-2">
                  <Row id="height-left">
                    <Col lg="12" className="mb-3">
                      <CardBody
                        style={{
                          display: "flex",
                          justifyItems: "center",
                          justifyContent: "center",
                        }}
                        className="p-2 horoscope-box horoscope-inner-1 rounded-lg h-full bg-white shadow-sm overflow-x-hidden verflow-y-auto border"
                      >
                        <Form className="bg-none py-3">
                          <Card
                            className="mb-0 px-[0.8rem]"
                            style={{
                              height: "100%",
                              justifyContent: "center",
                              justifyItems: "center",
                              display: "flex",
                            }}
                          >
                            <Row>
                              <Col md="6" className="form-group mw-50 bg-none">
                                <label>Name</label>
                                <Input
                                  bsSize="sm"
                                  type="text"
                                  className={
                                    !validation.uname
                                      ? "form-control rounded-[2px] pl-2 mb-0"
                                      : "form-control rounded-[2px] pl-2 mb-0"
                                  }
                                  name="uname"
                                  tabIndex="1"
                                  placeholder="Name"
                                  maxLength={"50"}
                                  value={
                                    localkundalidata.name
                                      ? localkundalidata.name
                                      : inputValues.uname
                                  }
                                  onChange={(e) => {
                                    nameChange(e);
                                    handleChangeName(e);
                                  }}
                                  readOnly={alldatatrue}
                                />
                                {validation.uname && (
                                  <p style={{ fontSize: "8px", color: "red" }}>
                                    {validation.uname}
                                  </p>
                                )}
                                {/* {validation.uname && console.log(validation)} */}
                                <label>Place of Birth</label>
                                <Input
                                  bsSize="sm"
                                  type="text"
                                  className="form-control py-0 rounded-[2px] pl-2"
                                  name="place"
                                  tabIndex="2"
                                  placeholder="Place of Birth"
                                  value={
                                    localkundalidata.pob.placeName
                                      ? localkundalidata.pob.placeName
                                      : inputValues.place
                                  }
                                  onChange={(e) => {
                                    searchPlace(e);
                                    handleChange(e);
                                  }}
                                  readOnly={alldatatrue}
                                  autoComplete="off"
                                />
                                {validation.place && (
                                  <p style={{ fontSize: "8px", color: "red" }}>
                                    {validation.place}
                                  </p>
                                )}
                                {/* {validation.place && console.log(validation)} */}
                                {dropDown && (
                                  <div
                                    className="placeContainer scrollbar scrollbar-primary"
                                    tabIndex="3"
                                  >
                                    {placeList &&
                                      placeList.map((val, index) => (
                                        <div
                                          className="place cursor-pointer"
                                          index={index}
                                          onClick={() => selectPlace(val)}
                                        >
                                          {val.placeName && (
                                            <div className="placeName">
                                              {val.placeName}
                                            </div>
                                          )}
                                          <div className="placeName">{"-"}</div>
                                          {val.StateName && (
                                            <div className="StateName">
                                              {val.StateName}
                                            </div>
                                          )}
                                          {val.StateName && (
                                            <div className="placeName">
                                              {"-"}
                                            </div>
                                          )}
                                          <div className="countryName">
                                            {val.countryName}
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                )}
                                <div style={{ display: "flex" }}>
                                  <div className="latitude">
                                    <label>Latitude</label>
                                    <Input
                                      bsSize="sm"
                                      type="text"
                                      className="form-control py-0 rounded-[2px] pl-2"
                                      name="latitude"
                                      placeholder="Latitude"
                                      value={
                                        localkundalidata.latitude
                                          ? localkundalidata.latitude
                                          : inputValues.latitude
                                      }
                                      readOnly
                                    />
                                  </div>
                                  <div className="longitude">
                                    <label>Longitude</label>
                                    <Input
                                      bsSize="sm"
                                      type="text"
                                      className="form-control py-0 rounded-[2px] pl-2"
                                      name="longitude"
                                      placeholder="Longitude"
                                      value={
                                        localkundalidata.langitutde
                                          ? localkundalidata.langitutde
                                          : inputValues.longitude
                                      }
                                      readOnly
                                    />
                                  </div>
                                </div>

                                <div>
                                  <label className="mb-0">Language</label>
                                  <Input
                                    bsSize="sm"
                                    type="select"
                                    className="form-control py-0 rounded-[2px] pl-2"
                                    name="language"
                                    style={{ marginTop: "6px" }}
                                    onChange={(e) => {
                                      languageSelect(e);
                                      handleChange(e);
                                    }}
                                    tabIndex="3"
                                    required
                                    disabled={alldatatrue}
                                  >
                                    <option>Select Language</option>
                                    {languageList.map((val) => (
                                      <option
                                        value={val.id}
                                        selected={
                                          localkundalidata.language == val.id
                                        }
                                      >
                                        {val.language}
                                      </option>
                                    ))}
                                  </Input>
                                  {validation.language && (
                                    <p
                                      style={{
                                        fontSize: "8px",
                                        color: "red",
                                      }}
                                    >
                                      {validation.language}
                                    </p>
                                  )}
                                  {/* {validation.language &&
                                    console.log(validation)} */}
                                </div>

                                <Row className="mt-1">
                                  <Col
                                    md="12"
                                    style={{
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <label>Product</label>
                                    <Input
                                      bsSize="sm"
                                      type="select"
                                      className="form-control py-0 rounded-[2px] pl-2"
                                      name="product"
                                      tabIndex="5"
                                      onChange={(e) => {
                                        setGenerateKundaliProduct(
                                          e.target.value
                                        );
                                        setProductlist(e.target.value);
                                        handleChange(e);
                                        setMessage("");
                                      }}
                                      disabled={alldatatrue}
                                    >
                                      <option value="00">Select Product</option>
                                      {productList.map((val) => (
                                        <option
                                          value={val.id}
                                          selected={
                                            localkundalidata.product == val.id
                                          }
                                        >
                                          {val.name}
                                        </option>
                                      ))}
                                    </Input>
                                    {validation.product && (
                                      <p
                                        style={{
                                          fontSize: "8px",
                                          color: "red",
                                        }}
                                      >
                                        {validation.product}
                                      </p>
                                    )}
                                    {/* {validation.product &&
                                      console.log(validation)} */}
                                  </Col>
                                </Row>

                                <Row className="mt-1">
                                  <Col
                                    md="12"
                                    style={{
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <label>Prediction For</label>
                                    <Input
                                      bsSize="sm"
                                      type="select"
                                      className="form-control py-0 rounded-[2px] pl-2"
                                      name="predfor"
                                      tabIndex="5"
                                      onChange={(e) => {
                                        setRotateKundali(e.target.value);
                                        handleChange(e);
                                      }}
                                      disabled={alldatatrue}
                                    >
                                      <option>Prediction For</option>
                                      {rotatekundaliList.map((val) => (
                                        <option
                                          value={val.id}
                                          selected={
                                            localkundalidata.rotatekundali ==
                                            val.id
                                          }
                                        >
                                          {val.name}
                                        </option>
                                      ))}
                                    </Input>
                                    {validation.predfor && (
                                      <p
                                        style={{
                                          fontSize: "8px",
                                          color: "red",
                                        }}
                                      >
                                        {validation.predfor}
                                      </p>
                                    )}
                                    {/* {validation.predfor &&
                                      console.log(validation)} */}
                                  </Col>
                                </Row>
                              </Col>
                              <Col md="6" className="form-group mw-50">
                                <Row>
                                  <Col lg="12" md="6" className="">
                                    <label>Date of Birth</label>
                                    {console.log(
                                      inputValues.day,
                                      "inputValues.day"
                                    )}
                                    <Row>
                                      <Col md="3" className="mb-2 dob-input">
                                        <Input
                                          bsSize="sm"
                                          tabIndex="6"
                                          type="text"
                                          className="form-control dob-input px-1 rounded-[2px] pl-2"
                                          name="day"
                                          maxLength={2}
                                          placeholder="day"
                                          onBlur={(e) => addZeroes(e, "day")}
                                          value={
                                            localkundalidata.birthDate.day
                                              ? localkundalidata.birthDate.day
                                              : inputValues.day
                                          }
                                          onChange={(e) => {
                                            dayChange(e);
                                            handleChange(e);
                                          }}
                                          readOnly={
                                            alldatatrue &&
                                            !(dayhoursmin == "day")
                                          }
                                        />
                                        {validation.day && (
                                          <p
                                            className="dob-input"
                                            style={{
                                              fontSize: "8px",
                                              color: "red",
                                              marginRight: "-10px",
                                            }}
                                          >
                                            {validation.day}
                                          </p>
                                        )}
                                        {/* {validation.day &&
                                          console.log(validation)} */}
                                      </Col>
                                      <Col md="3" className="mb-2 dob-input">
                                        <Input
                                          bsSize="sm"
                                          type="text"
                                          className="form-control dob-input px-1 rounded-[2px] pl-2"
                                          name="month"
                                          tabIndex="7"
                                          placeholder="month"
                                          maxLength={2}
                                          onBlur={(e) => addZeroes(e, "month")}
                                          value={
                                            localkundalidata.birthDate.month
                                              ? localkundalidata.birthDate.month
                                              : inputValues.month
                                          }
                                          onChange={(e) => {
                                            monthChange(e);
                                            handleChange(e);
                                          }}
                                          readOnly={alldatatrue}
                                        />
                                        {validation.month && (
                                          <p
                                            className="dob-input"
                                            style={{
                                              fontSize: "8px",
                                              color: "red",
                                            }}
                                          >
                                            {validation.month}
                                          </p>
                                        )}
                                        {/* {validation.month &&
                                          console.log(validation)} */}
                                      </Col>
                                      <Col md="3" className="mb-2 dob-input">
                                        <Input
                                          bsSize="sm"
                                          type="text"
                                          className="form-control dob-input px-1 rounded-[2px] pl-2"
                                          name="year"
                                          tabIndex="8"
                                          placeholder="year"
                                          maxLength={4}
                                          value={
                                            localkundalidata.birthDate.year
                                              ? localkundalidata.birthDate.year
                                              : inputValues.year
                                          }
                                          onChange={(e) => {
                                            yearChange(e);
                                            handleChange(e);
                                          }}
                                          readOnly={alldatatrue}
                                        />
                                        {validation.year && (
                                          <p
                                            className="dob-input"
                                            style={{
                                              fontSize: "8px",
                                              color: "red",
                                            }}
                                          >
                                            {validation.year}
                                          </p>
                                        )}
                                        {/* {validation.year &&
                                          console.log(validation)} */}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <label className="mt-1 mr-1">
                                  Time of Birth
                                </label>

                                <Row>
                                  <Col md="12" className="d-flex">
                                    <div
                                      style={{ display: "flex", gap: "10px" }}
                                      className="lg:w-2/3 md:w-[50%] md-min:w-[70%]"
                                    >
                                      <div className="w-1/2">
                                        <Input
                                          bsSize="sm"
                                          tabIndex="9"
                                          type="number"
                                          min={0}
                                          max={23}
                                          className="form-control px-1 rounded-[2px] pl-2 pr-2"
                                          name="hours"
                                          maxLength={2}
                                          placeholder="Hours"
                                          onBlur={(e) => addZeroes(e, "hours")}
                                          style={{
                                            width: "100%",
                                            marginLeft: "5%",
                                          }}
                                          value={
                                            localkundalidata.birthTime.hour
                                              ? localkundalidata.birthTime.hour
                                              : inputValues.hours
                                          }
                                          onChange={(e) => {
                                            hourChange(e);
                                          }}
                                          readOnly={
                                            alldatatrue &&
                                            !(dayhoursmin == "hour")
                                          }
                                        />
                                        {validation.hours && (
                                          <p
                                            style={{
                                              fontSize: "8px",
                                              color: "red",
                                              marginLeft: "2.5px",
                                            }}
                                          >
                                            {validation.hours}
                                          </p>
                                        )}
                                      </div>
                                      <div className="w-1/2">
                                        <Input
                                          bsSize="sm"
                                          type="number"
                                          min={0}
                                          max={59}
                                          maxLength="2"
                                          className="form-control px-1 rounded-[2px] pl-2"
                                          name="minutes"
                                          tabIndex="10"
                                          placeholder="Minutes"
                                          onBlur={(e) =>
                                            addZeroes(e, "minutes")
                                          }
                                          style={{
                                            width: "100%",
                                            marginLeft: "9%",
                                          }}
                                          value={
                                            localkundalidata.birthTime.minute
                                              ? localkundalidata.birthTime
                                                  .minute
                                              : inputValues.minutes
                                          }
                                          onChange={(e) => {
                                            minuteChange(e);
                                          }}
                                          readOnly={
                                            alldatatrue &&
                                            !(dayhoursmin == "minutes")
                                          }
                                        />
                                        {validation.minutes && (
                                          <p
                                            style={{
                                              fontSize: "8px",
                                              color: "red",
                                              marginLeft: "5.5px",
                                            }}
                                          >
                                            {validation.minutes}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="mt-1">
                                  <Col md="12" className="">
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        className="check-div"
                                        style={{ marginRight: "10%" }}
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-control rounded-[2px] pl-2"
                                          name="male"
                                          tabIndex="12"
                                          checked={
                                            gender === "male" ? true : false
                                          }
                                          onChange={(e) => {
                                            // console.log("IN MALE", "");
                                            setMale(e);
                                            handleChange(e);
                                          }}
                                          disabled={alldatatrue}
                                        />
                                        <label
                                          htmlFor="vehicle1"
                                          style={{ marginBottom: "0" }}
                                        >
                                          Male
                                        </label>
                                      </div>
                                      <div className="check-div">
                                        <input
                                          type="checkbox"
                                          className="form-control rounded-[2px] pl-2"
                                          name="female"
                                          tabIndex="13"
                                          checked={
                                            gender === "female" ? true : false
                                          }
                                          onChange={(e) => {
                                            // console.log("IN MALE", "");
                                            setFemale(e);
                                            handleChange(e);
                                          }}
                                          disabled={alldatatrue}
                                        />
                                        <label
                                          htmlFor="vehicle1"
                                          style={{ marginBottom: "0" }}
                                        >
                                          Female
                                        </label>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col
                                    md="12"
                                    className="d-flex"
                                    style={{
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {/* {console.log(planStatus, "planStatus")} */}
                                    <Input
                                      bsSize="sm"
                                      type="select"
                                      className="form-control py-0 px-1 h-[27px] rounded-[2px] pl-2"
                                      name="hours"
                                      placeholder="HH"
                                      tabIndex="16"
                                      style={{ width: "50%" }}
                                      onChange={(e) =>
                                        setNewDhm(e.target.value)
                                      }
                                    >
                                      <option value="0" select>
                                        Select Value
                                      </option>
                                      <option value="day">Day</option>
                                      <option value="hour">Hour</option>
                                      <option value="minutes">Minute</option>
                                    </Input>
                                    {planStatus === 0 ? (
                                      <a
                                        type="submit"
                                        className="form-control py-0 px-1 h-[27px] rounded-[2px]"
                                        style={{ width: "10%" }}
                                        onClick={(e) => {
                                          checkSubscription(e);
                                        }}
                                        disabled
                                      >
                                        <i className="tim-icons icon-minimal-left mt-2"></i>
                                      </a>
                                    ) : planStatus === 1 ? (
                                      <a
                                        type="submit"
                                        className="form-control py-0 px-1 h-[27px] rounded-[2px]"
                                        style={{ width: "10%" }}
                                        onClick={(e) => changeDecdate(e)}
                                      >
                                        <i className="tim-icons icon-minimal-left mt-2"></i>
                                      </a>
                                    ) : (
                                      <a
                                        type="submit"
                                        className="form-control py-0 px-1 h-[27px] rounded-[2px]"
                                        style={{ width: "10%" }}
                                        onClick={(e) => FetchError(e)}
                                        disabled
                                      >
                                        <i className="tim-icons icon-minimal-left mt-2"></i>
                                      </a>
                                    )}
                                    <input
                                      type="text"
                                      className="form-control py-0 px-1 h-[27px] rounded-[2px]"
                                      value={incrementmin}
                                      onChange={(e) =>
                                        updatetob(e.target.value)
                                      }
                                      name="hours"
                                      placeholder="HH"
                                      tabIndex="17"
                                      style={{ width: "20%" }}
                                    />
                                    {planStatus === 0 ? (
                                      <a
                                        type="submit"
                                        className="form-control py-0 px-1 h-[27px] rounded-[2px]"
                                        style={{ width: "10%" }}
                                        disabled
                                        onClick={(e) => {
                                          checkSubscription(e);
                                        }}
                                      >
                                        <i className="tim-icons icon-minimal-right mt-2" />
                                      </a>
                                    ) : planStatus === 1 ? (
                                      <a
                                        herf="/#"
                                        type="submit"
                                        className="form-control py-0 px-1 h-[27px] rounded-[2px]"
                                        style={{ width: "10%" }}
                                        onClick={(e) => changeIncdate(e)}
                                      >
                                        <i className="tim-icons icon-minimal-right mt-2" />
                                      </a>
                                    ) : (
                                      <a
                                        type="submit"
                                        className="form-control py-0 px-1 h-[27px] rounded-[2px]"
                                        style={{ width: "10%" }}
                                        onClick={(e) => FetchError(e)}
                                        disabled
                                      >
                                        <i className="tim-icons icon-minimal-right mt-2" />
                                      </a>
                                    )}
                                  </Col>
                                </Row>

                                <Row>
                                  <Col
                                    md="12"
                                    className="mt-2 pl-3"
                                    style={{
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Row className="g-0">
                                      <Col
                                        md="6"
                                        className="lg:pl-[14px] lg:pr-1 max-[786px]:pr-4"
                                      >
                                        {planStatus === 0 ? (
                                          <a
                                            className="btn dashboardbtn rounded-2 px-0 rounded-[2px]"
                                            tabIndex="18"
                                            onClick={(e) => {
                                              checkSubscription(e);
                                            }}
                                            type="submit"
                                            disabled
                                          >
                                            Generate Report
                                          </a>
                                        ) : planStatus === 1 ? (
                                          <Button
                                            className="btn dashboardbtn px-0 rounded-[2px]"
                                            tabIndex="18"
                                            onClick={(e) => {
                                              checkValidation(e);
                                            }}
                                            disabled={alldatatrue}
                                          >
                                            Generate Report
                                          </Button>
                                        ) : (
                                          <a
                                            className="btn dashboardbtn rounded-2 exp px-0 rounded-[2px]"
                                            tabIndex="18"
                                            disabled
                                            type="submit"
                                            onClick={FetchError}
                                          >
                                            Generate Report
                                          </a>
                                        )}
                                      </Col>
                                      <Col
                                        md="6"
                                        className="lg:pl-1 lg:pr-4 max-[786px]:pl-4"
                                      >
                                        <Button
                                          className="btn dashboardbtn rounded-[2px]"
                                          tabIndex="18"
                                          onClick={(e) =>
                                            checkValidationsave(e)
                                          }
                                        >
                                          Save Kundali
                                        </Button>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="12">
                                        <Button
                                          className="btn dashboardbtn rounded-[2px] py-[7px]"
                                          style={{
                                            width: "100%",
                                          }}
                                          tabIndex="18"
                                          onClick={(e) => clearAllData(e)}
                                        >
                                          Clear
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                {splans == 4 && (
                                  <div>
                                    <Row className="mt-0">
                                      <Col
                                        md="12"
                                        lg="12"
                                        className="form-group"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {ispedLoading ? (
                                          <Threedotscomppred isclass={true} />
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </Row>
                                    <Row className="mt-1">
                                      <Col md="12" className="mt-0 pl-3">
                                        <div className="message">
                                          {alldatatrue && !ispedLoading ? (
                                            <p>
                                              {" "}
                                              <Input
                                                bsSize="sm"
                                                type="select"
                                                className="form-control px-0 py-0 rounded-[2px]"
                                                name="product"
                                                tabIndex="5"
                                                onChange={(e) => {
                                                  setGenerateKundaliProduct(
                                                    e.target.value
                                                  );
                                                }}
                                                disabled
                                              >
                                                {productList.map((val) => (
                                                  <option
                                                    value={
                                                      "Vashist Jyotish 1 Year"
                                                    }
                                                    selected={
                                                      generateKundaliProduct ==
                                                      "Vashist Jyotish 1 Year"
                                                    }
                                                  >
                                                    {val.name}
                                                  </option>
                                                ))}
                                              </Input>
                                            </p>
                                          ) : null}
                                        </div>
                                      </Col>
                                      <Col
                                        md="6"
                                        className="mt-2 lg:pl-[15px] lg:pr-0 max-[786px]:pr-[15px] py-1"
                                      >
                                        {alldatatrue && !ispedLoading ? (
                                          <div className="d-flex align-items-baseline">
                                            <Button
                                              className="btn btn-default px-0 max-[1300px]:text-[10px] xl:text-[10px] rounded-[2px] py-[7px]"
                                              style={{
                                                // padding: "5px 0px",
                                                marginTop: "-3px",
                                                width: "100%",
                                              }}
                                              tabIndex="18"
                                              onClick={(e) =>
                                                checkValidationpred(
                                                  generateKundaliProduct
                                                )
                                              }
                                            >
                                              Generate Kundali
                                            </Button>
                                          </div>
                                        ) : null}
                                      </Col>
                                      <Col
                                        md="6"
                                        className="lg:pl-0 lg:pr-[15px] max-[786px]:pl-[15px] py-1"
                                      >
                                        {alldatatrue &&
                                        !ispedLoading &&
                                        message ? (
                                          <div>
                                            <a
                                              href={message}
                                              role="button"
                                              className="btn btn-sm max-[1300px]:text-[10px] xl:text-[10px] rounded-[2px] py-[7px]"
                                              style={{
                                                padding: "",
                                                overflow: "wrap",
                                                color: "#ffffff",
                                                borderColor: "#1d8cf8",
                                                background: "#2d3b5e",
                                                border: "1px solid",
                                                // borderRadius: "8px",
                                                fontWeight: "600",
                                                width: "100%",
                                              }}
                                              target="_blank"
                                            >{`Download `}</a>
                                          </div>
                                        ) : (
                                          <div>
                                            {alldatatrue && !ispedLoading && (
                                              <a
                                                href={message}
                                                role="button"
                                                className="btn btn-sm max-[1300px]:text-[10px] xl:text-[10px] rounded-[2px] py-[7px]"
                                                style={{
                                                  padding: "",
                                                  overflow: "wrap",
                                                  color: "#ffffff",
                                                  borderColor: "#1d8cf8",
                                                  background: "#2d3b5e",
                                                  border: "1px solid",
                                                  // borderRadius: "8px",
                                                  fontWeight: "600",
                                                  width: "100%",
                                                }}
                                                disabled
                                                target="_blank"
                                              >{`Download `}</a>
                                            )}
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Card>
                        </Form>
                      </CardBody>
                    </Col>
                    <Col lg="12">
                      <CardBody className="bg-white maha-dasha overflow-auto w-full shadow-sm rounded-lg mb-3">
                        <div className="subh-ashubh w-full">
                          <Subhasubh />
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </Col>
                <Col lg="7" md="12">
                  <Row>
                    <Col lg="12">
                      <Row noGutters>
                        <Col
                          lg="6"
                          md="12"
                          className="px-2 mb-3"
                          id="height-chart"
                        >
                          <CardBody className="horoscope-box rounded-lg p-0 mb-0 shadow-sm bg-white border h-[21rem] overflow-auto">
                            <Horoinnertwo />
                          </CardBody>
                        </Col>
                        <Col lg="6" md="12" className="px-2 mb-3">
                          <CardBody className="rounded-lg w-full mb-0 h-full align-self-center bg-white shadow-sm border lg:p-0 max-md:p-0 max-md:pb-3">
                            <Horoinnerthree
                              birthdate={birthDate}
                              finalyear={yearsBetween}
                              // finalyear={finalyear}
                              language={language}
                              gochardata={gocharShodhit}
                            />
                          </CardBody>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg="12" md="12">
                      <Row noGutters className="rounded-lg">
                        <Col md="12" className="px-2">
                          <CardBody className="bg-white maha-dasha w-full shadow-sm rounded-lg">
                            <Mahadasha
                              userDetail={userdetails1}
                              heightLeft={heightLeft}
                              heightChart={heightChart}
                            />
                          </CardBody>
                        </Col>
                        {/* <Col lg="12" className="max-lg:block hidden px-2 mt-3">
                          <CardBody className="bg-white maha-dasha overflow-auto w-full shadow-sm rounded-lg mb-3">
                            <div className="subh-ashubh w-full">
                              <Subhasubh />
                            </div>
                          </CardBody>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                {/* <Col lg="5" className="horoscope-box rounded-sm xl:h-[25rem]">
                
              </Col> */}
              </Row>
            </Col>
          </Row>
        </Container>
        {/* </div> */}
      </div>
      <div className="formmodal card border-lg">
        <Modal size="sm" isOpen={modalIsOpen} onRequestClose={closeModal}>
          <ModalHeader style={{ background: "", padding: "10px 10px" }}>
            <span>
              <h4 className="mb-0">Save Kundali</h4>
              <button onClick={closeModal} className="lkaclose">
                <i className="fa fa-close"></i>
              </button>
            </span>
          </ModalHeader>
          <ModalBody className="p-3 text-center">
            <Form>
              <textarea
                type="text"
                className="form-control border-2 w-full p-2"
                value={remark}
                onChange={changeRemark}
              />
            </Form>
            <button className="btn btn-success btn-sm mt-3" onClick={saveData}>
              Save Kundali
            </button>
          </ModalBody>
        </Modal>
      </div>

      <div className="formmodal card">
        <Modal size="lg" isOpen={questionmodal} onRequestClose={closeModal}>
          <ModalHeader
            style={{ padding: "10px 15px", background: "#292f343d" }}
          >
            <h4 className="mb-0">
              <strong>Questions & Answers</strong>
            </h4>
            <button onClick={closeModal} className="lkaclose">
              X
            </button>
          </ModalHeader>
          {
            <ModalBody>
              {isQuLoading ? (
                <Threedotscomp />
              ) : (
                <>
                  {questionasn &&
                    questionasn.map((quesans, index) => (
                      <>
                        <p>
                          <strong>{Parser(quesans.que)}</strong>
                        </p>
                        <p>{Parser(quesans.ans)}</p>
                      </>
                    ))}
                  {badquestionasn &&
                    badquestionasn.map((badques, index) => (
                      <>
                        <p>
                          <strong>{Parser(badques.que)}</strong>
                        </p>
                        <p>{Parser(badques.ans)}</p>
                      </>
                    ))}
                  {childquestionasn &&
                    childquestionasn.map((child, index) => (
                      <>
                        <p>
                          <strong>{Parser(child.que)}</strong>
                        </p>
                        <p>{Parser(child.ans)}</p>
                      </>
                    ))}
                  {foreignquestionasn &&
                    foreignquestionasn.map((foreign, index) => (
                      <>
                        <p>
                          <strong>{Parser(foreign.que)}</strong>
                        </p>
                        <p>{Parser(foreign.ans)}</p>
                      </>
                    ))}
                  {goodquestionasn &&
                    goodquestionasn.map((good, index) => (
                      <>
                        <p>
                          <strong>{Parser(good.que)}</strong>
                        </p>
                        <p>{Parser(good.ans)}</p>
                      </>
                    ))}
                  {healthquestionasn &&
                    healthquestionasn.map((health, index) => (
                      <>
                        <p>
                          <strong>{Parser(health.que)}</strong>
                        </p>
                        <p>{Parser(health.ans)}</p>
                      </>
                    ))}
                  {lovequestionasn &&
                    lovequestionasn.map((love, index) => (
                      <>
                        <p>
                          <strong>{Parser(love.que)}</strong>
                        </p>
                        <p>{Parser(love.ans)}</p>
                      </>
                    ))}
                  {occupationquestionasn &&
                    occupationquestionasn.map((occupation, index) => (
                      <>
                        <p>
                          <strong>{Parser(occupation.que)}</strong>
                        </p>
                        <p>{Parser(occupation.ans)}</p>
                      </>
                    ))}
                  {parrentquestionasn &&
                    parrentquestionasn.map((parents, index) => (
                      <>
                        <p>
                          <strong>{Parser(parents.que)}</strong>
                        </p>
                        <p>{Parser(parents.ans)}</p>
                      </>
                    ))}
                  {vehiclequestionasn &&
                    vehiclequestionasn.map((vehicle, index) => (
                      <>
                        <p>
                          <strong>{Parser(vehicle.que)}</strong>
                        </p>
                        <p>{Parser(vehicle.ans)}</p>
                      </>
                    ))}
                  {marriagequestionasn &&
                    marriagequestionasn.map((marriage, index) => (
                      <>
                        <p>
                          <strong>{Parser(marriage.que)}</strong>
                        </p>
                        <p>{Parser(marriage.ans)}</p>
                      </>
                    ))}
                </>
              )}
            </ModalBody>
          }
        </Modal>
        <Modal isOpen={modalvideo} toggle={seModalVideo} size="lg">
          <ModalHeader style={{ background: "#2022243d", padding: "5px 10px" }}>
            <span>
              <h4 className="mb-0">GDV PANEL Guide</h4>
            </span>
          </ModalHeader>
          <ModalBody style={{ height: "500px" }}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/vlDzYIIOYmM"
              title="YouTube video"
              allowfullscreen
            ></iframe>
          </ModalBody>
          <button onClick={seModalVideo} className="submitbtn1">
            Skip
          </button>
        </Modal>
      </div>
      <Modal
        modalClassName="modal-search"
        isOpen={modalSearch}
        toggle={closeModalSearch}
      >
        <ModalHeader style={{ padding: "10px 15px", background: "#292f343d" }}>
          <h4 className="mb-0">Notification</h4>
          <button
            aria-label="Close"
            className="close p-0"
            data-dismiss="modal"
            type="button"
            onClick={closeModalSearch}
          >
            <i className="tim-icons icon-simple-remove" />
          </button>
        </ModalHeader>

        <div className="modal-body">
          {notification && notification[0] ? (
            <p>{notification[0].message}</p>
          ) : (
            ""
          )}
        </div>
      </Modal>
    </>
  );
};

export default Dashboard;
